import {Component} from 'react';
import {Container, Row, Col, Form, FormGroup, Label, Input, InputGroupAddon, InputGroupText, InputGroup, Button, CustomInput} from 'reactstrap';
import Search from '../../component/Search/Search';
import {InputKelahiranHistory} from '../../component/History/History';
import Filter from '../../form/Filter/Filter';
import moment from 'moment';
import siteConfig from '../../config/config'

import token from '../../helper/token';
import {handleResponse} from '../../helper/SinatriaHelper';

export default class Kelahiran extends Component{
	constructor(props){
		super(props)
		this.state = {
			formData: {
                date: moment().format('YYYY-MM-DD'),
                cempe: '',
                jantan: '',
                betina: '',
                bobot: '',
				status:'',
				customTreatment: '',
				treatment:'',
				"bersih-lendir": false,
				"antiseptik": false,
				"cek-fisik": false,
				"inisiasi-menyusui": false,
				"--lainnya": false,
				data: {
					tanggalLahir: new Date(),
					noCempe: null,
					noInduk: null,
					noJantan: null,
					bobotLahir: null
				}
			},
			historys:[],
			inputTreatmentDisplay: 'none'
		}
	}

    refreshHistory = () => {
		//history
		fetch(`${siteConfig.api_url}kelahiran`,{
			method: "GET",
			headers: {"token": token.getToken()}
		})
		.then(handleResponse)
		.then( resJson => {
			this.setState({
				historys: resJson.data
			})
		})
	}

	componentDidMount(){
		this.refreshHistory();
	}

	isActiveFilterHandle = () => {
		this.setState({
			isActiveFilter: !this.state.isActiveFilter
		})
	}

	generateTreatmentData = () => {
		let treatment = []
		if(this.state.formData['bersih-lendir']){
			treatment.push('pembersihan lendir di hidung dan mulut')
		}
		if(this.state.formData['antiseptik']){
			treatment.push('pemberian antiseptik pusar')
		}
		if(this.state.formData['cek-fisik']){
			treatment.push('pengecekan fisik lengkap')
		}
		if(this.state.formData['inisiasi-menyusui']){
			treatment.push('inisiasi menyusui dini')
		}
		if(this.state.formData['--lainnya']){
			treatment.push(this.state.formData.customTreatment)
		}
		this.setState({
			formData:{
				...this.state.formData,
				treatment: JSON.stringify(treatment)
			}
		},()=>{
			// console.log(this.state.formData);
		})
	}

	handleData = (e) => {
		let name = e.target.name
		let value = e.target.value
		this.setState({
			formData:{
				...this.state.formData,
				[name]:value
			}
		},()=>{
            // console.log(this.state);
			this.generateTreatmentData()
        })
	}
    handleSwitch = (e) => {
		let name = e.target.name
		let value = e.target.checked
		if(name==='--lainnya'){
			if(value){
				this.setState({inputTreatmentDisplay:'block'})
			}else{
				this.setState({inputTreatmentDisplay:'none'})
			}
		}
		this.setState({
			formData:{
				...this.state.formData,
				[name]:value
			}
		},()=>{
			// console.log(this.state.formData)
			this.generateTreatmentData()
		})
    }

    handleSubmit = (e) => {
        e.preventDefault();
		fetch(`${siteConfig.api_url}kelahiran`,{
			method: "POST",
			headers: {
				"token": token.getToken(),
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(this.state.formData)
		})
		.then(handleResponse)
		.then( resJson => {
            this.setState({
                formData:{
                    ...this.state.formData,
                    date: moment().format('YYYY-MM-DD'),
                    cempe: '',
                    jantan: '',
                    betina: '',
                    bobot: '',
					status:'',
					"bersih-lendir": false,
					"antiseptik": false,
					"cek-fisik": false,
					"inisiasi-menyusui": false,
					"--lainnya": false,
					customTreatment: ''
                }
            })
			this.refreshHistory();
            this.setState({successAlertVisible: true})
		})
	}

	handleDeleteClick = (id) => {
		fetch(`${siteConfig.api_url}kelahiran/${id}`,{
			method: "DELETE",
			headers: {
				"token": token.getToken(),
				'Content-Type': 'application/json'
			}
		})
		.then(handleResponse)
		.then( resJson => {
            this.refreshHistory();
		}).catch((err)=>{
			console.log(err);
		})
	}
	render(){
		return(
			<>
			{this.state.isActiveFilter?<Filter close={this.isActiveFilterHandle} />:null}
				{/* ===== Section Input ===== */}
				<Container>
					<h3 className="text-center mt-5">INPUT KELAHIRAN</h3>
					<Form onSubmit={this.handleSubmit}>
						<Container>
							<Row className="mt-3">
								<Col xs="6" className="pr-1 pl-0">
									<FormGroup>
										<Label for="tanggalLahir">Tanggal Lahir</Label>
										<Input bsSize="sm" id="tanggalLahir" name="date" type="date" onChange={this.handleData} value={this.state.formData.date}/>
									</FormGroup>
								</Col>
								<Col xs="6" className="pl-1 pr-0">
									<FormGroup>
										<Label for="nomorCempe">Nomor Cempe</Label>
										<Input bsSize="sm" id="nomorCempe" name="cempe" type="text" onChange={this.handleData} value={this.state.formData.cempe}/>
									</FormGroup>
								</Col>
							</Row>
							<Row className="mt-3">
								<Col xs="6" className="pr-1 pl-0">
									<FormGroup>
										<Label for="nomorInduk">Nomor Induk</Label>
										<Input bsSize="sm" id="nomorInduk" name="betina" type="text" onChange={this.handleData} value={this.state.formData.betina}/>
									</FormGroup>
								</Col>
								<Col xs="6" className="pl-1 pr-0">
									<FormGroup>
										<Label for="nomorJantan">Nomor Jantan</Label>
										<Input bsSize="sm" id="nomorJantan" name="jantan" type="text" onChange={this.handleData} value={this.state.formData.jantan}/>
									</FormGroup>
								</Col>
							</Row>
							<Row className="mt-3">
								<Col xs="6" className="pr-1 pl-0">
									<FormGroup>
										<Label for="bobotLahir">Bobot Lahir</Label>
										<InputGroup size="sm">
											<Input bsSize="sm" id="bobotLahir" name="bobot" type="number" onChange={this.handleData} value={this.state.formData.bobot}/>
									        <InputGroupAddon addonType="append">
									    	    <InputGroupText>Kg</InputGroupText>
									        </InputGroupAddon>
									    </InputGroup>
									</FormGroup>
								</Col>
								<Col xs="6" className="pl-1 pr-0">
									<FormGroup>
										<Label for="status">Status Lahir</Label>
										<Input name='status' type="select" id="status" onChange={this.handleData} value={this.state.formData.status}>
											<option value='normal'>Normal</option>
											<option value='sungsang'>Sungsang</option>
											<option value='prematur'>Prematur</option>
											<option value='mati'>Mati</option>
										</Input>
									</FormGroup>
								</Col>
							</Row>
							<Row className="mt-3">
								<Col xs="6" className="pr-1 pl-0">
									Perlakuan Pasca Lahir:<br/>
									<FormGroup>
										<CustomInput type="switch" name='bersih-lendir' label='pembersihan lendir di hidung dan mulut' id='treat-1' onChange={this.handleSwitch} checked={this.state.formData["bersih-lendir"]}/>
									</FormGroup>
									<FormGroup>
										<CustomInput type="switch" name='antiseptik' label='pemberian antiseptik pusar' id='treat-2' onChange={this.handleSwitch} checked={this.state.formData["antiseptik"]}/>
									</FormGroup>
									<FormGroup>
										<CustomInput type="switch" name='cek-fisik' label='pengecekan fisik lengkap' id='treat-3' onChange={this.handleSwitch} checked={this.state.formData["cek-fisik"]}/>
									</FormGroup>
									<FormGroup>
										<CustomInput type="switch" name='inisiasi-menyusui' label='inisiasi menyusui dini' id='treat-4' onChange={this.handleSwitch} checked={this.state.formData["inisiasi-menyusui"]}/>
									</FormGroup>
									<FormGroup>
										<CustomInput type="switch" name='--lainnya' label='lainnya:' id='treat-5' onChange={this.handleSwitch} checked={this.state.formData["--lainnya"]}/>
										<Input type="text" name="customTreatment" style={{display:this.state.inputTreatmentDisplay}} onChange={this.handleData} value={this.state.formData.customTreatment}/>
									</FormGroup>
								</Col>
							</Row>
							<Row className="mt-3">
								<Col xs="6" className="pr-1 pl-0">

								</Col>
								<Col xs="6" className="pl-1 pr-0">
									<FormGroup>
										<Button style={{marginTop:'2.2em'}} color="success" size="sm" block>INPUT</Button>
									</FormGroup>
								</Col>
							</Row>
						</Container>
					</Form>
				</Container>

				{/* ===== History Section ===== */}
				<section>
					<Container>
						<h4><b>Riwayat Kelahiran Ternak</b></h4>
						<Search onClick={(this.isActiveFilterHandle)} />
					</Container>
					{this.state.historys.map(history=>{
						return <InputKelahiranHistory data={history} handleDeleteClick={id=>this.handleDeleteClick(id)} key={history.id}/>
					})}
				</section>

			</>
		)
	}
}