import {Component, Fragment} from 'react';
import PopUp from '../../component/PopUp/PopUp';
import {Container, Row, Col, FormGroup, Label, Input} from 'reactstrap';
import Search from '../../component/Search/Search';
import {InputHarianHistory} from '../../component/History/History';
import Filter from '../Filter/Filter';
import './InputHarian.css';

const fakeDb = [[
	'jumlahPakanLebih','Ternak Jumlah Pakan Lebih', 
		[{id:1, tanggal:new Date('03/17/2021'), noternak:"3034"},
		{id:2, tanggal:new Date('03/12/2021'), noternak:"3033"},
		{id:3, tanggal:new Date('03/10/2021'), noternak:"3032"},
		{id:4, tanggal:new Date('03/08/2021'), noternak:"3031"},
		{id:5, tanggal:new Date('03/06/2021'), noternak:"3030"},]
	],[
	'sisaPakanBanyak','Ternak Sisa Pakan Banyak',
		[{id:1, tanggal:new Date('02/19/2021'), noternak:"3874"},
		{id:2, tanggal:new Date('02/18/2021'), noternak:"3873"},
		{id:3, tanggal:new Date('02/17/2021'), noternak:"3872"},
		{id:4, tanggal:new Date('02/16/2021'), noternak:"3871"},
		{id:5, tanggal:new Date('02/15/2021'), noternak:"3870"},]
	],[
	'tidakNafsuMakan','Ternak Tidak Nafsu Makan',
		[{id:1, tanggal:new Date('06/24/2021'), noternak:"7214"},
		{id:2, tanggal:new Date('06/21/2021'), noternak:"7213"},
		{id:3, tanggal:new Date('06/18/2021'), noternak:"7212"},
		{id:4, tanggal:new Date('06/15/2021'), noternak:"7211"},
		{id:5, tanggal:new Date('06/12/2021'), noternak:"7210"},]
	]
]

export default class InputHarian extends Component{
	constructor(props){
		super(props)
		this.state={
			formData: {
				data:{
					tanggal: new Date(),
					noternak: null
				}
			},
			menuActive: 'tidakNafsuMakan',
			historyTitle: fakeDb.find(data=>data[0]==='tidakNafsuMakan')[1],
			isActiveFilter: false,
			historys: fakeDb.find(data=>data[0]==='tidakNafsuMakan')[2]
		}
	}


	isActiveFilterHandle = () => {
		this.setState({
			isActiveFilter: !this.state.isActiveFilter
		})
	}

	changeMenu = (menu) => {
		this.setState({
			menuActive: menu,
			historyTitle: fakeDb.find(data=>data[0]===menu)[1],
	    	historys: fakeDb.find(data=>data[0]===menu)[2]
		})
	}

	handleHistoryPatch = data => {
		this.setState({
			formData:{data}
		}, ()=>{
			console.log(this.state.formData)
		})
	}

	handleHistoryDelete = id => {
		alert('hapus '+id)
	}

	handleData = (e) => {
		let name = e.target.name
		let value = e.target.value
		this.setState({
			formData:{
				...this.state.formData,
				[name]:value
			}
		},()=>{
			console.log(this.state.formData)
		})
	}

	render(){
		return(
			<Fragment>
				{this.state.isActiveFilter?<Filter close={this.isActiveFilterHandle} />:null}
				<PopUp onClick={this.props.close}>
					{/* === Navigation === */}
					<nav>
						<Container>
							<h4 className='mt-5'>INPUT PAKAN HARIAN</h4>
							<Row className="text-center mr-1 ml-1 justify-content-center">
								<hr/>
								<Col xs={this.state.menuActive==='tidakNafsuMakan'?"4 pl-0 pr-0 nav active-ih":"4 pl-0 pr-0 nav"} onClick={()=>this.changeMenu('tidakNafsuMakan')}>
									<p>Tidak Nafsu Makan</p>
								</Col>
								<Col xs={this.state.menuActive==='sisaPakanBanyak'?"4 pl-0 pr-0 nav active-ih":"4 pl-0 pr-0 nav"} onClick={()=>this.changeMenu('sisaPakanBanyak')}>
									<p>Sisa Pakan Banyak</p>
								</Col>
								<Col xs={this.state.menuActive==='jumlahPakanLebih'?"4 pl-0 pr-0 nav active-ih":"4 pl-0 pr-0 nav"} onClick={()=>this.changeMenu('jumlahPakanLebih')}>
									<p>Jumlah Pakan Lebih</p>
								</Col>
							</Row>
						</Container>
					</nav>

					{/* === Form === */}
					<section id="input">
						<Container>
							<Row className="mt-3">
								<Col xs="6" className="pr-0">
									<FormGroup>
										<Label for="tanggal">Tanggal</Label>
										<Input bsSize="sm" id="tanggal" name="tanggal" type="date" onChange={this.handleData} value={
											(this.state.formData.tanggal)? this.state.formData.tanggal:this.state.formData.data.tanggal.toJSON().split('T')[0]
										}/>
									</FormGroup>
								</Col>
								<Col xs="3" className="pl-1 pr-0">
									<FormGroup>
										<Label for="nomorTernak">Nomor Ternak</Label>
										<Input bsSize="sm" id="nomorTernak" name="nomorTernak" type="number" onChange={this.handleData} value={this.state.formData.data.noTernak}/>
									</FormGroup>
								</Col>
								<Col xs="2" className="pl-1">
									<FormGroup>
										<button style={{marginTop:'30px'}} className="btn btn-success btn-sm">INPUT</button>
									</FormGroup>
								</Col>
							</Row>
						</Container>
					</section>

					{/* === History === */}
					<section id="history">
						<Container>
							<h3>{this.state.historyTitle}</h3>
							<Search onClick={(this.isActiveFilterHandle)} />
							{this.state.historys.map(history=>{
								return <InputHarianHistory data={history} key={history.id} handleEditClick={data=>this.handleHistoryPatch(data)} handleDeleteClick={id=>this.handleHistoryDelete(id)}/>
							})}
						</Container>
					</section>


				</PopUp>
			</Fragment>
		)
	}
}