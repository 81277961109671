import axios from "axios";
import SiteConfig from "../config/config";
import token from "../helper/token";

export default function getData(method, path, config) {
  console.log('headers :' + JSON.stringify({
    token: token.getToken(),
    "Content-Type": "application/json",
  }));

  const axiosInstance = axios.create({
    baseURL: SiteConfig.api_url,
    headers: {
      token: token.getToken(),
      "Content-Type": "application/json",
    },
  });

  return axiosInstance({
    method: method, // Gunakan method dari parameter
    url: path, // Izinkan konfigurasi tambahan jika diperlukan
    ...config
  }).then((response) => {
    if (!response.data.message) {
      if ([400].indexOf(response.status) !== -1) {
        window.location.href = "/login";
      }

      const error = (response.data && response.data.message) || response.statusText;
      return Promise.reject(error);
    }

    return response.data
  });
}
