import {Component} from 'react';
import {Container, Row, Col, Form, FormGroup, Label, Input, InputGroupAddon, InputGroupText, InputGroup, Alert} from 'reactstrap';
import Search from '../../component/Search/Search';
import {InputBobotTernakHistory} from '../../component/History/History';
import Filter from '../../form/Filter/Filter';
import moment from 'moment';
import { withRouter } from 'react-router';
import siteConfig from '../../config/config'

import token from '../../helper/token';
import {handleResponse} from '../../helper/SinatriaHelper';

class BobotHarian extends Component{
	constructor(props){
		super(props)
		this.state = {
			formData: {
				data: {
					tanggal: new Date(),
					noTernak: null,
					bobot: null
				},
                date: moment().format('YYYY-MM-DD'),
			},
			historys:[],
            successAlertVisible: false,
            successMessage: 'Data berhasil disimpan.'
		}
	}
    
	refreshHistory = () => {
		//history
		fetch(`${siteConfig.api_url}bobot`,{
			method: "GET",
			headers: {"token": token.getToken()}
		})
		.then(handleResponse)
		.then( resJson => {
			this.setState({
				allHistory: resJson.data,
				historys: resJson.data
			})
		})
	}

	componentDidMount(){
		this.refreshHistory();
	}

	isActiveFilterHandle = () => {
		this.setState({
			isActiveFilter: !this.state.isActiveFilter
		})
	}
	handleData = (e) => {
		let name = e.target.name
		let value = e.target.value
		this.setState({
			formData:{
				...this.state.formData,
				[name]:value
			}
		},()=>{
			console.log(this.state.formData)
		})
	}
	handleHistoryPatch = data => {
		this.setState({
			formData:{data:data}
		}, ()=>{
			console.log(this.state.formData)
		})
	}
	handleHistoryDelete = id => {
		fetch(`${siteConfig.api_url}bobot/${id}`,{
			method: "DELETE",
			headers: {
				"token": token.getToken(),
				'Content-Type': 'application/json'
			}
		})
		.then(handleResponse)
		.then( () => {
			this.refreshHistory();
            this.setState({successAlertVisible: true, successMessage: "Data berhasil dihapus"})
		})
	}

	handleSubmit = (e) => {
        e.preventDefault();
		fetch(`${siteConfig.api_url}bobot`,{
			method: "POST",
			headers: {
				"token": token.getToken(),
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(this.state.formData)
		})
		.then(handleResponse)
		.then( resJson => {
            this.setState({
                formData:{
                    ...this.state.formData,
                    code: '',
                    bobot: ''
                }
            })
			this.refreshHistory();
            this.setState({successAlertVisible: true, successMessage: "Data berhasil disimpan"})
		})
	}

	render(){
		return(
			<>
				{this.state.isActiveFilter?<Filter close={this.isActiveFilterHandle} />:null}
                <Alert color="success" isOpen={this.state.successAlertVisible} toggle={()=> this.setState({successAlertVisible: false})}>
                    {this.state.successMessage}
                </Alert>
				{/* ===== Section Input ===== */}
				<Container>
					<h3 className="text-center mt-5">INPUT BOBOT TERNAK</h3>
					<Form onSubmit={this.handleSubmit}>
						<FormGroup>
							<Label for="tanggal">Tanggal Penimbangan</Label>
							<Input id="tanggal" name="date" type="date" onChange={this.handleData} value={this.state.formData.date} required/>
						</FormGroup>
						<FormGroup>
							<Container>
								<Row className="mt-3">
									<Col xs="5" className="pr-1 pl-0">
										<FormGroup>
											<Label for="kodeTernak">Kode Ternak</Label>
											<Input bsSize="sm" id="kodeTernak" name="code" type="text" onChange={this.handleData} value={this.state.code} required/>
										</FormGroup>
									</Col>
									<Col xs="5" className="pl-1 pr-1">
										<FormGroup>
											<Label for="bobot">Bobot</Label>
											<InputGroup size="sm">
												<Input bsSize="sm" id="bobot" name="bobot" type="number" onChange={this.handleData} value={this.state.bobot} required/>
										        <InputGroupAddon addonType="append">
										        	<InputGroupText>Kg</InputGroupText>
										        </InputGroupAddon>
										    </InputGroup>
										</FormGroup>
									</Col>
									<Col xs="2" className="pl-1">
										<FormGroup>
											<button style={{marginTop:'32px'}} className="btn btn-success btn-sm">SIMPAN</button>
										</FormGroup>
									</Col>
								</Row>
							</Container>
						</FormGroup>
					</Form>
				</Container>

				{/* ===== History Section ===== */}
				<section>
                    <Container>
                        <h4><b>Riwayat Bobot Ternak</b></h4>
                        <Search onClick={(this.isActiveFilterHandle)} />
                        {this.state.historys.map((history,index)=>{
                            return <InputBobotTernakHistory data={history} handleEditClick={data=>this.handleHistoryPatch(data)} handleDeleteClick={id=>this.handleHistoryDelete(id)} key={index}/>
                        })}
                    </Container>
				</section>

			</>
		)
	}
}

export default withRouter(BobotHarian)