import {Container, Row, Col, Form, FormGroup, Label, Input, Button} from 'reactstrap';
import {Component} from 'react';
import PopUp from '../../component/PopUp/PopUp';
import moment from 'moment'
import {formatRupiah, handleResponse} from '../../helper/SinatriaHelper'
import siteConfig from '../../config/config'
import token from '../../helper/token';

export default class TambahPenjualan extends Component {
	constructor(props) {
	    super(props);
	    this.state = {
	      judulForm: 'TAMBAH PENJUALAN',
	      formData:{
			  date: moment().format('YYYY-MM-DD'),
			  price: '',
			  quantity: 1,
			  customCategory: ''
		  },
		  inputCategoryDisplay: 'none'
	    };
	  }
	handleData = (e) => {
		let keys = e.target.name
		let value = e.target.value
		if(keys==='category'){
			if(value==='--lainnya'){
				this.setState({inputCategoryDisplay:'block'})
			}else{
				this.setState({inputCategoryDisplay:'none'})
			}
		}
		this.setState({
			formData:{
				...this.state.formData,
				[keys]: value,
			}
		})
	}

	submitData = (e) => {
		e.preventDefault();
		fetch(`${siteConfig.api_url}sales`,{
			method: "POST",
			headers: {
				"token": token.getToken(),
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(this.state.formData)
		})
		.then( handleResponse )
		.then( resJson => {
			window.location.reload();
		})
	}

	render(){
		return(
			<PopUp onClick={this.props.close}>
				<Container>
					<h4 className="mt-5 mb-3">TAMBAH PENJUALAN</h4>
					<Form onSubmit={this.submitData}>
					  <FormGroup>
				        <Label for="tanggalInput">Tanggal</Label>
				        <Input type="date" name="date" id="tanggalInput" onChange={this.handleData} value={this.state.formData.date} required/>
				      </FormGroup>
				      <FormGroup>
				        <Label for="Namainput">Nama</Label>
				        <Input type="text" name="name" id="Namainput" onChange={this.handleData} required/>
				      </FormGroup>
				      <FormGroup>
				        <Label for="kategoriPenjualanInput">Kategori Penjualan</Label>
				        <Input type="select" name="category" id="kategoriPenjualanInput" onChange={this.handleData} required>
				          <option value="">Pilih</option>
							{this.props.categories.map(kat=>{
							return <option value={kat.id} key={kat.id}>{kat.name}</option>
							})}
						  <option value="--lainnya">Lainnya</option>
				        </Input>
						<br/>
						<Input type="text" name="customCategory" style={{display:this.state.inputCategoryDisplay}} placeholder="Input Kategori" onChange={this.handleData} value={this.state.formData.customCategory} />
				      </FormGroup>
				      <FormGroup>
				      	<Row>
				      		<Col xs="4">
						        <Label for="jumlahInput">Jumlah</Label>
						        <Input type="number" name="quantity" id="jumlahInput" onChange={this.handleData} value={this.state.formData.quantity} required/>
				      		</Col>
				      		<Col xs="8">
						        <Label for="hargaSatuanInput">Harga Satuan Penjualan</Label>
						        <Input type="text" name="price" id="hargaSatuanInput" onChange={this.handleData} value={this.state.formData.price} required/>
				      		</Col>
				      	</Row>
				      </FormGroup>
				      <FormGroup>
					        <Label for="totalPenjualan">Total Penjualan</Label>
					        <Input type="text" name="totalPenjualan" id="totalPenjualan" onChange={this.handleData} value={formatRupiah(this.state.formData.quantity * this.state.formData.price)} readOnly/>
				      </FormGroup>
				      <FormGroup>
					        <Label for="hargaModalInput">Harga Modal</Label>
					        <Input type="text" name="capital" id="hargaModalInput" onChange={this.handleData} required/>
				      </FormGroup>
				      <FormGroup>
					        <Label for="namaPembeli">Nama Pembeli</Label>
					        <Input type="text" name="nama-pembeli" id="namaPembeli" onChange={this.handleData} required/>
				      </FormGroup>
				      <FormGroup>
					        <Label for="alamatPembeli">Alamat Pembeli</Label>
					        <Input type="text" name="alamat-pembeli" id="alamatPembeli" onChange={this.handleData} required/>
				      </FormGroup>
				      <FormGroup>
					        <Label for="noHpPembeli">No. HP Pembeli</Label>
					        <Input type="text" name="no-hp" id="noHpPembeli" onChange={this.handleData} required/>
				      </FormGroup>
				      <Button className="btn btn-success btn-block">{this.state.judulForm}</Button>
				    </Form>
				</Container>
			</PopUp>
		)
	}
}