import {Component, Fragment} from 'react';
import {Container} from 'reactstrap';
// import Search from '../../component/Search/Search';
import {TernakCard} from '../../component/Card/Card';
import UbahStatusTernak from '../../form/UbahStatusTernak/UbahStatusTernak';

import {handleResponse} from '../../helper/SinatriaHelper';
import token from '../../helper/token';
import siteConfig from '../../config/config';
import moment from 'moment';
import { withRouter } from 'react-router';
import DetailTernak from '../../component/Modal/DetailTernak';

class LaporanDataTernak extends Component{
	constructor(props){
		super(props)
		this.state = {
			statusForm: {
				date: moment().format('YYYY-MM-DD'),
				code: '',
				currentStatus: ''
			},
			ternakCards: [],
			isActiveUbahStatus: 'none',
			userIdParams: (this.props.match.params.alumniID?`userid=${this.props.match.params.alumniID}`:''),
			isActiveDetail: false,
			ternakDetail: {
				date: moment(),
				code: '',
				jenis: '',
				kelamin: '',
				tujuan: '',
				umur: '',
				status: '',
				harga_beli: '',
				nama_supplier: '',
				kontak_supplier: '',
				img0: null,
				img1: null,
				img2: null,
				img3: null
			}
		}
	}

	refreshData = () => {
		fetch(`${siteConfig.api_url}ternak?${this.state.userIdParams}`,{
			method: "GET",
			headers: {"token": token.getToken()}
		})
		.then(handleResponse)
		.then( resJson => {
			this.setState({
				ternakCards: resJson.data
			})
		})
	}

	componentDidMount = () => {
		this.refreshData();
	}

	cardStatusClick = (data) => {
		this.setState({
			statusForm: data,
			isActiveUbahStatus: 'block'
		})
	}

	cardDetailClick = (ternak_id) => {
		fetch(`${siteConfig.api_url}ternak/${ternak_id}`,{
			method: "GET",
			headers: {"token": token.getToken()}
		})
		.then(handleResponse)
		.then( resJson => {
			this.setState({
				ternakDetail: resJson.data
			})
		});
		this.setState({
			isActiveDetail: true
		})
	}

	cardDeleteClick = (ternak_id) => {
		fetch(`${siteConfig.api_url}ternak/${ternak_id}`,{
			method: "DELETE",
			headers: {"token": token.getToken()}
		})
		.then(handleResponse)
		.then( () => {
			this.refreshData();
		});
	}

	closeUbahStatus = () => {
		this.setState({isActiveUbahStatus:'none'})
	}

	ubahStatusSubmit = () => {
		this.closeUbahStatus();
		this.refreshData();
	}

	toggleModal = () => {
		this.setState({
			isActiveDetail: !this.state.isActiveDetail
		})
	}

	render(){
		return(
			<Fragment>
				<UbahStatusTernak display={this.state.isActiveUbahStatus} data={this.state.statusForm} close={this.closeUbahStatus} ubahStatusSubmit={this.ubahStatusSubmit}/>
				<DetailTernak data={this.state.ternakDetail} modal={this.state.isActiveDetail} toggle={this.toggleModal}/>
				<Container>
					<h5 className="text-center">Laporan Data Ternak</h5>
					<hr/>
					{/* <Search style={{boxShadow:"0 0 20px -10px grey"}}/> */}
					{this.state.ternakCards.length>=1?(
						this.state.ternakCards.map(ternakCard=>{
							return <TernakCard data={ternakCard} key={ternakCard.id} cardStatusClick={ data=>this.cardStatusClick(data) } cardDetailClick={(ternak_id)=>this.cardDetailClick(ternak_id)} cardDeleteClick={(ternak_id)=>this.cardDeleteClick(ternak_id)} />
						})
					):(
						<div className="text-center">
							<strong>Belum Ada Data Ternak</strong>
						</div>
					)}
					<div style={{height:'60px'}}>{/*Space*/}</div>
				</Container>
			</Fragment>
		)
	}
}

export default withRouter(LaporanDataTernak)