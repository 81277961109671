import {Component} from 'react';
import { Link } from 'react-router-dom';
import {Container, Alert, Button} from 'reactstrap';

export default class NotFound extends Component {

	render(){
		return(
			<Container>
                <div style={{height:'100px'}}></div>
                <Alert color="danger" style={{minHeight:'200px'}}>
                    <div className="text-center">
                        <img src="/img/sinatriafarm-logo.png" alt="sinatriafarm-logo" className="logo" style={{maxWidth:'150px'}}/>
                        <br/><br/>
                        Halaman yang Anda tuju tidak ditemukan.<br/><br/>
                        <Link to="/">
                            <Button color="danger">Kembali ke Halaman Utama</Button>
                        </Link>
                    </div>
                </Alert>
			</Container>
		)
	}
}