import {Component, Fragment} from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import './Apps.css';
import moment from 'moment'
import "moment/locale/id"

import ScrollToTop from '../component/ScrollToTop'

import Header from '../component/Header/Header';
import AppBar from '../component/AppBar/Appbar';

import Dashboard from './Dashboard/Dashboard';
import Keuangan from './Keuangan/Keuangan';
import SplashScreen from './SplashScreen/SplashScreen';
import InputTernak from './Input/InputTernak';
import PakanHarian from './PakanHarian/PakanHarian';
import KesehatanHarian from './Input/KesehatanHarian';
import BobotHarian from './Input/BobotHarian';
import Perkawinan from './Input/Perkawinan';
import Kelahiran from './Input/Kelahiran';
import ProfilePage from './Profile/Profile';
import AlumniProfilePage from './Profile/AlumniProfile';
import AlumniPage from './Alumni/Alumni';
import LaporanDataTernak from './LaporanDataTernak/LaporanDataTernak';
import LaporanFarm from './LaporanFarm/LaporanFarm';
import RegisterPage from './RegisterPage/RegisterPage';
import NotFound from './ErrorPage/NotFound';
import Treatment from './Input/Treatment';

class App extends  Component{
	render(){
		return(
			<Fragment>
				<BrowserRouter>
					<ScrollToTop/>
					<Switch>
						<Route path="/login" exact>
							<SplashScreen/>
						</Route>
						<Route path="/register" exact>
							<RegisterPage/>
						</Route>
						<Route path="/laporan-farm" exact>
							<Header/>
							<h2>Laporan Farm</h2>
							<AppBar/>
						</Route>
						<Route path="/pengeluaran" exact>
							<Header/>
							<Keuangan pageData={{
								formName: 'TambahPengeluaran',
								secondHeaderTitle: `Total Pengeluaran Bulan ${moment().format('MMMM YYYY')}`,
								saldoTotal: '4.092.123.013',
								mainButtonText: 'TAMBAH PENGELUARAN',
								historyTitle: 'Riwayat Pengeluaran',
								historys: [
									['05 Mei 2021','pengeluaran ternak',2450000],
									['03 Mei 2021','pengeluaran patokan umbaran ternak',2900000],
									['01 Mei 2021','pengeluaran luar negeri',33500000],
									['31 April 2021','pengeluaran dalam negeri',38000000]
								],
								kategori: ['satu','dua','tiga'],
								path: 'spend'
							}}/>
							<AppBar/>
						</Route>
						<Route path="/pembelian" exact>
							<Header/>
							<Keuangan pageData={{
								formName: 'TambahPembelian',
								secondHeaderTitle: 'Total Pembelian Bulan Mei 2021',
								saldoTotal: '4.092.123.013',
								mainButtonText: 'TAMBAH PEMBELIAN',
								historyTitle: 'Riwayat Pembelian',
								historys: [
									['05 Mei 2021','pembelian ternak',2450000],
									['03 Mei 2021','pembelian patokan umbaran ternak',2900000],
									['01 Mei 2021','pembelian luar negeri',33500000],
									['31 April 2021','pembelian dalam negeri',38000000]
								],
								kategori: ['satu','dua','tiga'],
								path: 'purchase'
							}}/>
							<AppBar/>
						</Route>
						<Route path="/penjualan" exact>
							<Header/>
							<Keuangan pageData={{
								formName: 'TambahPenjualan',
								secondHeaderTitle: 'Total Penjualan Bulan Mei 2021',
								saldoTotal: '4.092.123.013',
								mainButtonText: 'TAMBAH PENJUALAN',
								historyTitle: 'Riwayat Penjualan',
								historys: [
									['04 Mei 2021','penjualan ternak',2450000],
									['02 Mei 2021','penjualan patokan umbaran ternak',2900000],
									['02 Mei 2021','penjualan luar negeri',33500000],
									['31 April 2021','penjualan dalam negeri',38000000]
								],
								kategori: ['satu','dua','tiga'],
								path: 'sales'
							}}/>
							<AppBar/>
						</Route>
						<Route path="/dashboard" exact>
							<Header/>
							<Dashboard />
							<AppBar/>
						</Route>
						<Route path="/input-ternak" exact>
							<Header/>
							<InputTernak />
							<AppBar/>
						</Route>
						<Route path="/pakan-harian" exact>
							<Header/>
							<PakanHarian />
							<AppBar/>
						</Route>
						<Route path="/kesehatan-harian" exact>
							<Header/>
							<KesehatanHarian/>
							<AppBar/>
						</Route>
						<Route path="/bobot-harian" exact>
							<Header/>
							<BobotHarian/>
							<AppBar/>
						</Route>
						<Route path="/perkawinan" exact>
							<Header/>
							<Perkawinan/>
							<AppBar/>
						</Route>
						<Route path="/kelahiran" exact>
							<Header/>
							<Kelahiran/>
							<AppBar/>
						</Route>
						<Route path="/treatment" exact>
							<Header/>
							<Treatment/>
							<AppBar/>
						</Route>
						<Route path="/data-ternak" exact>
							<Header/>
							<LaporanDataTernak />
							<AppBar/>
						</Route>
						<Route path="/report" exact>
							<Header/>
							<LaporanFarm/>
							<AppBar/>
						</Route>
						<Route path="/profile" exact>
							<Header/>
							<ProfilePage/>
							<AppBar/>
						</Route>
						<Route path="/alumni" exact>
							<Header/>
							<AlumniPage />
							<AppBar/>
						</Route>
						<Route path="/alumni/:alumniID/report" exact>
							<Header/>
							<LaporanFarm />
							<AppBar/>
						</Route>
						<Route path="/alumni/:alumniID/ternak" exact>
							<Header/>
							<LaporanDataTernak />
							<AppBar/>
						</Route>
						<Route path="/alumni/:alumniID/profile" exact>
							<Header/>
							<AlumniProfilePage />
							<AppBar/>
						</Route>
						<Route path="/" exact>
							<Header/>
							<Dashboard />
							<AppBar/>
						</Route>
						<Route component={NotFound} />
					</Switch>
				</BrowserRouter>
			</Fragment>
		)
	}
}

export default App;