import {Component} from 'react';
import { Button } from 'reactstrap';
import PopUp from '../../component/PopUp/PopUp';
// import {Container, Row, Col, Label, Input, Button, Form} from 'reactstrap';
// import moment from 'moment';

import {handleResponse} from '../../helper/SinatriaHelper';
import token from '../../helper/token';
import siteConfig from '../../config/config';

export default class DeleteConfirm extends Component {

    deleteAlumni = () => {
        fetch(`${siteConfig.api_url}alumni/${this.props.id}`,{
            method: "DELETE",
            headers: {"token": token.getToken()}
        })
        .then(handleResponse)
        .then( () => {
            this.props.close('deleted');
        })
    }

	render(){
		let active = {display:this.props.display}
		return(
			<div style={active}>
			<PopUp onClick={this.props.close} style={{zIndex:'101', position: 'fixed', top: '0', padding: '10px'}}>
                <br />
                <Button onClick={this.deleteAlumni} className="btn btn-sm mt-2 w-100 btn-danger">Yakin ?</Button>
			</PopUp>
			</div>
		)
	}
}