import {Component} from 'react';
import {Container, Row, Col, FormGroup, Label, Input, Form, Button, Alert} from 'reactstrap';
import InputFileImage from '../../component/InputFileImage/InputFileImage';
import {handleResponse} from '../../helper/SinatriaHelper';
import token from '../../helper/token';
import siteConfig from '../../config/config';
import { withRouter } from 'react-router';

class AlumniProfilePage extends Component {
	state = {
		gambarProfile: '',
        profileimg: null,
        formData: {
            name: '',
            phone: '',
            prov: '',
            kab: '',
            kec: '',
            kel: '',
            address: '',
			password: ''
        },
		isSuccess: false,
		isError: false,
		errorMessage: ''
	}

    componentDidMount = () => {
		const urlFetch = fetch(`${siteConfig.api_url}user/${this.props.match.params.alumniID}`,{
			method: "GET",
			headers: {"token": token.getToken()}
		  })
		urlFetch
		.then(handleResponse)
		.then( resJson => {
			this.setState({
				formData: resJson.data
			})
            if(resJson.data.img){
                this.setState({
                    gambarProfile: siteConfig.img_url+resJson.data.img
                },()=>{
                    // console.log(this.state);
                })
            }
		})
    }

	handleImage = (e) => {
		e.target.parentElement.style.maxHeight = 'fit-content'
		e.target.parentElement.style.overflow = 'visible'
		e.target.parentElement.style.boxShadow = 'none'
		e.target.parentElement.style.borderRadius = '0'
		this.setState({
			gambarProfile: URL.createObjectURL( e.target.files[0] ),
            profileimg: e.target.files[0]
		}, () => {
			//Data belum dimasukkan ke formData.
			// console.log(this.state)
		})
	}

	handleData = (e) => {
		let name = e.target.name
		let value = e.target.value
		this.setState({
			formData:{
				...this.state.formData,
				[name]:value
			}
		},()=>{
			// console.log(this.state.formData)
		})
	}

    handleSubmit = (e) => {
        e.preventDefault();
		this.setState({
			isError: false,
			isSuccess: false
		})
        let fd = new FormData();
        if(this.state.profileimg){
            fd.append('photo',this.state.profileimg);
        }
        for ( var key in this.state.formData ) {
            if (this.state.formData[key]) fd.append(key, this.state.formData[key]);
        }
		fetch(`${siteConfig.api_url}alumni/${this.props.match.params.alumniID}`,{
			method: "PATCH",
			headers: {
				"token": token.getToken()
			},
			body: fd
		})
		.then(handleResponse)
		.then( resJson => {
			if(resJson.code===200){
            	this.setState({isSuccess:true})
			}else{
				this.setState({
					isError: true,
					errorMessage: resJson.message
				})
			}
		})
		.catch((err)=>{
			console.log(err);
			this.setState({
				isError: true,
				errorMessage: err
			})
		})
    }

	render(){
		return(
			<Container>
			<Form onSubmit={this.handleSubmit}>
				<Alert color="success" isOpen={this.state.isSuccess} toggle={()=> this.setState({isSuccess: false})}>
					Profile berhasil diupdate.
				</Alert>
				<Alert color="danger" isOpen={this.state.isError} toggle={()=> this.setState({isError: false})}>
					{this.state.errorMessage}
				</Alert>
				<Row>
					<Col xs="4">
						<InputFileImage onChange={this.handleImage} id="0" imgSrc={this.state.gambarProfile}/>
					</Col>
					<Col xs="8">
						<FormGroup>
							<Label for="nama">Nama</Label>
							<Input id="nama" name="name" type="text" placeholder="isikan nama" value={this.state.formData.name} onChange={this.handleData} />
						</FormGroup>
						<FormGroup>
							<Label for="noHp">No Handphone</Label>
							<Input id="noHp" name="phone" type="text" placeholder="isikan nomor handphone" readOnly value={this.state.formData.phone} />
						</FormGroup>
					</Col>
				</Row>
				<Label for="#">Alamat Farm</Label>
				<FormGroup>
					<Row>
						<Col xs="6">
							<Input name="prov" type="text" placeholder="provinsi" value={this.state.formData.prov} onChange={this.handleData} />
						</Col>
						<Col xs="6">
							<Input name="kab" type="text" placeholder="kabupaten" value={this.state.formData.kab} onChange={this.handleData} />
						</Col>
					</Row>
				</FormGroup>
				<FormGroup>
					<Row>
						<Col xs="6">
							<Input name="kec" type="text" placeholder="kecamatan" value={this.state.formData.kec} onChange={this.handleData} />
						</Col>
						<Col xs="6">
							<Input name="kel" type="text" placeholder="desa" value={this.state.formData.kel} onChange={this.handleData} />
						</Col>
					</Row>
				</FormGroup>
				<FormGroup>
					<Row>
						<Col xs="12">
							<Input name="address" type="textarea" placeholder="alamat lengkap" value={this.state.formData.address} onChange={this.handleData} />
						</Col>
					</Row>
				</FormGroup>
				<FormGroup>
					<Row>
						<Col xs="12">
							<Input name="password" type="password" placeholder="password" value={this.state.formData.password} onChange={this.handleData} />
						</Col>
					</Row>
				</FormGroup>
				<Button color="success" block>Update</Button>
			</Form>
			</Container>
		)
	}
}

export default withRouter(AlumniProfilePage);