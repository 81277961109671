import {Component} from 'react';
import PopUp from '../../component/PopUp/PopUp';
import {Form, FormGroup, Label, Input, Container} from 'reactstrap';
import moment from 'moment'

class Filter extends Component {
	constructor(props){
		super(props)
		this.state = {
			startDate: moment().subtract(30,'days').format('YYYY-MM-DD'),
			endDate: moment().format('YYYY-MM-DD'),
			category: null
		}
	}

	componentDidMount = () => {
		document.body.scrollTop = 0; // For Safari
		document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
	}

	handleData = (e) => {
		let name = e.target.name;
		let value = e.target.value;
		this.setState({
			[name]:value
		})
	}

	handleSubmit = (e) => {
		e.preventDefault();
		this.props.handleSubmit(this.state);
	}

	render(){
		return(
			<PopUp onClick={this.props.close} style={{zIndex:'101'}}>
				<Container>
					<h4 className="mt-5 text-center">FILTER</h4>
					<Form action="#!" onSubmit={this.handleSubmit}>
						<FormGroup>
							<Label>Rentang Waktu</Label>
							<div className="mb-3">
								<Input name="startDate" type="date" style={{width:"45%", float:'left', border:'1px solid #eee', borderRadius:'5px', padding:'6px 6px', textAlign:'center'}} onChange={this.handleData} value={this.state.startDate} />
								<p style={{width:'10%',float:'left', marginTop:'.4rem'}} className="text-center">s/d</p>
								<Input name="endDate" type="date" style={{width:"45%", float:'left', border:'1px solid #eee', borderRadius:'5px', padding:'6px 6px', textAlign:'center'}} onChange={this.handleData} value={this.state.endDate}/>
								<div style={{clear:'both'}}></div>
							</div>
						</FormGroup>
						{!this.props.kategori?null:
						<FormGroup>
							<Label for="kategoriPengeluaran">Kategori Pengeluaran</Label>
					        <Input type="select" name="category" id="kategoriPengeluaran" onChange={this.handleData}>
					          <option value="">Pilih</option>
					          {this.props.kategori.map(kat=>{
					          	return <option value={kat.id} key={kat.id}>{kat.name}</option>
					          })}
					        </Input>
						</FormGroup>
						}
						<FormGroup>
							<button className="btn btn-success btn-block">FILTER</button>
						</FormGroup>
					</Form>
				</Container>
			</PopUp>
		)
	}
}

export default Filter;