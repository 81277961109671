import {Fragment} from 'react';
import {Nav, NavItem, NavLink} from 'reactstrap';
import './Sidebar.css';

const Sidebar = (props) => {
	return(
		<Fragment> 
		<div className={ props.isOpen? "fullscreen-blur opened" : "fullscreen-blur" }  onClick={()=>props.off(true)}>
	    </div>
		<div className={ props.isOpen? "sidebar opened" : "sidebar" } >
			<img src="/img/sinatriafarm-logo.png" alt="sinatriafarm-logo"/>
	    	<Nav vertical>
		        <NavItem>
		          <NavLink href="/">Beranda</NavLink>
		          <hr/>
		        </NavItem>
		        <NavItem>
		          <NavLink href="/profile">Profil</NavLink>
		          <hr/>
		        </NavItem>
		        <NavItem>
		          <NavLink href="/report">Laporan</NavLink>
			      <hr/>
		        </NavItem>
	      	</Nav>
	      	<div className="copyright">
		      	<p>sanatria farm @ 2021</p>
	      	</div>
	    </div>
	    </Fragment>
	)
}

export default Sidebar;