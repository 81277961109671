import {Col} from 'reactstrap';
import {Link, NavLink} from 'react-router-dom';
import './SvgImage.css';

const Bars = props => {
	let barStyle = {
		position: 'absolute',
		display: "inline-block",
		width: '30px',
		top: '20px',
		right: '30px'
	}
	return(
		<svg style={barStyle} onClick={props.onClick} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bars" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill={props.fill} d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path></svg>
	)
}


// ===== Bottom Navbar =====
const Home = (props) => {
	return(
		<Col>
			<NavLink to={props.to} activeClassName="active" exact>
				<svg width={props.width} height={props.height} viewBox="0 0 27 29" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path fill={props.fill}  d="M14.1019 25.7893H18.7208V15.6677H14.1019V25.7893Z"/>
					<path fill={props.fill}  d="M23.332 10.4888L14.1019 2.39999L4.86401 10.4888V24.9459C4.86401 25.4098 5.21101 25.7893 5.63512 25.7893H12.5597V13.9808H20.2553V25.7893H22.5609C22.9927 25.7893 23.332 25.4098 23.332 24.9459V10.4888Z"/>
				</svg>
				<p>Home</p>
			</NavLink>
		</Col>
	);
}

const Profile = (props) => {
	return(
		<Col>
			<NavLink to={props.to} activeClassName="active" >
				<svg width={props.width} height={props.height} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
					<path fill={props.fill} d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"></path>
				</svg>
				<p>Profile</p>
			</NavLink>
		</Col>
	);
}

const Alumni = (props) => {
	return(
		<Col>
			<NavLink to={props.to} activeClassName="active" >
				<svg width={props.width} height={props.height} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="graduation-cap" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill={props.fill} d="M622.34 153.2L343.4 67.5c-15.2-4.67-31.6-4.67-46.79 0L17.66 153.2c-23.54 7.23-23.54 38.36 0 45.59l48.63 14.94c-10.67 13.19-17.23 29.28-17.88 46.9C38.78 266.15 32 276.11 32 288c0 10.78 5.68 19.85 13.86 25.65L20.33 428.53C18.11 438.52 25.71 448 35.94 448h56.11c10.24 0 17.84-9.48 15.62-19.47L82.14 313.65C90.32 307.85 96 298.78 96 288c0-11.57-6.47-21.25-15.66-26.87.76-15.02 8.44-28.3 20.69-36.72L296.6 284.5c9.06 2.78 26.44 6.25 46.79 0l278.95-85.7c23.55-7.24 23.55-38.36 0-45.6zM352.79 315.09c-28.53 8.76-52.84 3.92-65.59 0l-145.02-44.55L128 384c0 35.35 85.96 64 192 64s192-28.65 192-64l-14.18-113.47-145.03 44.56z"></path></svg>
				<p>Alumni</p>
			</NavLink>
		</Col>
	);	
}

const Logout = (props) => {
	return(
		<Col>
			<Link to="#!" onClick={props.onClick} style={{background:'transparent',border:'none'}}>
				<svg width={props.width} height={props.height} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="sign-out-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill={props.fill} d="M497 273L329 441c-15 15-41 4.5-41-17v-96H152c-13.3 0-24-10.7-24-24v-96c0-13.3 10.7-24 24-24h136V88c0-21.4 25.9-32 41-17l168 168c9.3 9.4 9.3 24.6 0 34zM192 436v-40c0-6.6-5.4-12-12-12H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h84c6.6 0 12-5.4 12-12V76c0-6.6-5.4-12-12-12H96c-53 0-96 43-96 96v192c0 53 43 96 96 96h84c6.6 0 12-5.4 12-12z"></path></svg>
				<p style={{color:'black'}}>Logout</p>
			</Link>
		</Col>
	);
}
// ===== Bottom Navbar End =====


// Just SVG
const Search = ({fill,width}) => {
	return(
		<svg aria-hidden="true" width={width} focusable="false" data-prefix="fas" data-icon="search" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill={fill} d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path></svg>
	)
}
const Filter = ({fill}) => {
	return(
		<svg width="33" height="28" viewBox="0 0 33 28" fill="none" xmlns="http://www.w3.org/2000/svg">
		<line x1="1" y1="4.33333" x2="32" y2="4.33333" stroke={fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
		<line x1="1" y1="14.4444" x2="32" y2="14.4444" stroke={fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
		<line x1="1" y1="24.5556" x2="32" y2="24.5556" stroke={fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
		<ellipse cx="9.89998" cy="3.88889" rx="3.3" ry="2.88889" fill="white" stroke={fill} strokeWidth="2"/>
		<ellipse cx="9.89998" cy="24.1111" rx="3.3" ry="2.88889" fill="white" stroke={fill} strokeWidth="2"/>
		<ellipse cx="23.1" cy="14" rx="3.3" ry="2.88889" fill="white" stroke={fill} strokeWidth="2"/>
		</svg>
	)
}
const Edit = ({width, fill, style, onClick}) => {
	return(
		<svg viewBox="0 0 512 512" width={width} fill={fill} style={style} onClick={onClick}>
			<path d="M352.459,220c0-11.046-8.954-20-20-20h-206c-11.046,0-20,8.954-20,20s8.954,20,20,20h206 C343.505,240,352.459,231.046,352.459,220z"/>
			<path d="M126.459,280c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20H251.57c11.046,0,20-8.954,20-20c0-11.046-8.954-20-20-20 H126.459z"/>
			<path d="M173.459,472H106.57c-22.056,0-40-17.944-40-40V80c0-22.056,17.944-40,40-40h245.889c22.056,0,40,17.944,40,40v123 c0,11.046,8.954,20,20,20c11.046,0,20-8.954,20-20V80c0-44.112-35.888-80-80-80H106.57c-44.112,0-80,35.888-80,80v352 c0,44.112,35.888,80,80,80h66.889c11.046,0,20-8.954,20-20C193.459,480.954,184.505,472,173.459,472z"/>
			<path d="M467.884,289.572c-23.394-23.394-61.458-23.395-84.837-0.016l-109.803,109.56c-2.332,2.327-4.052,5.193-5.01,8.345 l-23.913,78.725c-2.12,6.98-0.273,14.559,4.821,19.78c3.816,3.911,9,6.034,14.317,6.034c1.779,0,3.575-0.238,5.338-0.727 l80.725-22.361c3.322-0.92,6.35-2.683,8.79-5.119l109.573-109.367C491.279,351.032,491.279,312.968,467.884,289.572z M333.776,451.768l-40.612,11.25l11.885-39.129l74.089-73.925l28.29,28.29L333.776,451.768z M439.615,346.13l-3.875,3.867 l-28.285-28.285l3.862-3.854c7.798-7.798,20.486-7.798,28.284,0C447.399,325.656,447.399,338.344,439.615,346.13z"/>
			<path d="M332.459,120h-206c-11.046,0-20,8.954-20,20s8.954,20,20,20h206c11.046,0,20-8.954,20-20S343.505,120,332.459,120z"/>
		</svg>
	)
}


export {
	Bars,
	Home,
	Alumni,
	Logout,
	Profile,
	Search,
	Filter,
	Edit
};