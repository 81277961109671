import {Component} from 'react';
import { withRouter, Link } from 'react-router-dom';
import {Container, Form, FormGroup, Label, Input, Button, FormText, Alert} from 'reactstrap';
import './RegisterPage.css';
import SiteConfig from '../../config/config';
// import token from '../../helper/token';

let styleBg = {
  backgroundImage: 'url("/img/spashscreen-bg.png")',
  minHeight: '100vh'
}
let styleLink = {
  display: 'inline',
  color: 'blue'
}
let styleLogo = {
  width: '100%'
}

class RegisterPage extends Component {
	constructor(props){
		super(props)
		this.state = {
            name: '',
            phone: '',
            password: '',
            submitted: false,
            isError: false
		}

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
	}
  
  handleChange(e){
    const {name,value} = e.target;
    this.setState({[name]:value});
  }

  async handleSubmit(e){
    e.preventDefault();
    try{
        let response = await fetch(`${SiteConfig.api_url}user`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            name: this.state.name,
            phone: this.state.phone,
            password: this.state.password
          })
        });
    
        let result = await response.json();
        if(result.code===200){
            this.props.history.push("/login?register=success");
        }
        if(result.code===500&&result.data.code==='ER_DUP_ENTRY'){
            this.setState({
                isError: true
            })
        }
    }catch(err){
        console.log(err);
    }
  }

  render(){
    return (
      <div style={styleBg}>
        <div style={{height:'70px'}}></div>
        <Container className="">
          <div className="text-center">
            <img src="/img/sinatriafarm-logo.png" style={styleLogo} alt="sinatriafarm-logo" />
            <div className="tagLineAtas">
              <h2>Daftar sebagai Pengguna</h2>
            </div>
          </div>
            <Alert color="danger" isOpen={this.state.isError} toggle={()=> this.setState({isError: false})}>
                Nomor HP sudah terdaftar. Silakan mendaftar dengan nomor lain atau <Link to="/login">Login</Link>
            </Alert>
          <Form className="m-4" onSubmit={this.handleSubmit} id="form-login">
            <FormGroup>
              <Label for="name">Nama Lengkap</Label>
              <Input type="text" name="name" id="name" value={this.state.name} onChange={this.handleChange} />
            </FormGroup>
            <FormGroup>
              <Label for="no-handphone">no handphone</Label>
              <Input type="text" name="phone" id="no-handphone" value={this.state.phone} onChange={this.handleChange} />
            </FormGroup>
            <FormGroup>
              <Label for="password">password</Label>
              <Input type="password" name="password" id="password" value={this.state.password} onChange={this.handleChange} />
            </FormGroup>
            <FormGroup className="mt-4">
              <Button color="success" size="md" className="form-control">Daftar</Button>
            </FormGroup>
            <FormGroup className="mt-3 text-center">
              <FormText color="muted">Sudah punya akun?<Link to="/login" style={styleLink}> Login Sekarang</Link></FormText>
            </FormGroup>
            <div style={{height:'70px'}}></div>
          </Form>
        </Container>
      </div>
    );
  }
}

export default withRouter(RegisterPage)