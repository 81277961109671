const token = {
    getToken: () => {
        return localStorage.getItem('sinatriatoken');
    },
    setToken: (token) => {
        localStorage.setItem('sinatriatoken',token);
    },
    clearToken: () => {
        localStorage.removeItem('sinatriatoken');
    }
}

export default token;