import { Container, Row, Col } from "reactstrap";
import moment from "moment";
import "moment/locale/id";

// import {Edit} from '../SvgImage/SvgImage';
import { formatRupiah } from "../../helper/SinatriaHelper";

let styleLine = {
  borderBottom: "1px solid grey",
  fontSize: ".9em",
};
const History = ({ handleDeleteClick, ...props }) => {
  return (
    <Row className="ml-2 mr-2 pb-0 mt-3" style={styleLine}>
      <Col xs="4">
        <strong>{getTanggal(props.tanggal)}</strong>
        <p>{props.noternak}</p>
      </Col>
      <Col xs="4">
        <p>
          <strong>Diagnosis: </strong>
          <span className="text-muted"> {props.diagnosis}</span>
        </p>
      </Col>
      <Col xs="4" className="d-flex justify-content-around">
        {/* <Edit width="20" style={{marginTop:"0",display:'none'}} onClick={()=>handleEditClick({id:data.id, tanggal:data.date, noTernak:data.code})}/> */}
        <span className="ml-2">
          <b
            style={{ fontSize: "22px", lineHeight: ".9" }}
            onClick={() => handleDeleteClick()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24px"
              height="24px"
            >
              <g id="surface772249">
                <path
                  style={{
                    stroke: "none",
                    fillRule: "nonzero",
                    fill: "rgb(90.588236%,29.803923%,23.529412%)",
                    fillOpacity: "1",
                  }}
                  d="M 10 2 L 9 3 L 4 3 L 4 5 L 5 5 L 5 20 C 5 20.523438 5.191406 21.054688 5.570312 21.429688 C 5.945312 21.808594 6.476562 22 7 22 L 17 22 C 17.523438 22 18.054688 21.808594 18.433594 21.429688 C 18.808594 21.054688 19 20.523438 19 20 L 19 5 L 20 5 L 20 3 L 15 3 L 14 2 Z M 7 5 L 17 5 L 17 20 L 7 20 Z M 9 7 L 9 18 L 11 18 L 11 7 Z M 13 7 L 13 18 L 15 18 L 15 7 Z M 13 7 "
                />
              </g>
            </svg>
          </b>
        </span>
      </Col>
    </Row>
  );
};

const History2 = ({ text, handleDeleteClick }) => {
  return (
    <Row className="ml-2 mr-2 pb-0 mt-3" style={styleLine} key={text[0]}>
      <Col xs="4">
        <strong>{moment(text[1]).format("LL")}</strong>
        <p>{text[2]}</p>
      </Col>
      <Col xs="4" className="mt-2">
        <h6>
          <strong>{formatRupiah(text[3] * text[4])}</strong>
        </h6>
      </Col>
      <Col xs="4" className="d-flex justify-content-around align-items-center">
        {/* <Edit width="20" style={{marginTop:"0",display:'none'}} onClick={()=>handleEditClick({id:data.id, tanggal:data.date, noTernak:data.code})}/> */}
        <span className="ml-2">
          <b
            style={{ fontSize: "22px", lineHeight: ".9" }}
            onClick={() => handleDeleteClick(text[0])}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24px"
              height="24px"
            >
              <g id="surface772249">
                <path
                  style={{
                    stroke: "none",
                    fillRule: "nonzero",
                    fill: "rgb(90.588236%,29.803923%,23.529412%)",
                    fillOpacity: "1",
                  }}
                  d="M 10 2 L 9 3 L 4 3 L 4 5 L 5 5 L 5 20 C 5 20.523438 5.191406 21.054688 5.570312 21.429688 C 5.945312 21.808594 6.476562 22 7 22 L 17 22 C 17.523438 22 18.054688 21.808594 18.433594 21.429688 C 18.808594 21.054688 19 20.523438 19 20 L 19 5 L 20 5 L 20 3 L 15 3 L 14 2 Z M 7 5 L 17 5 L 17 20 L 7 20 Z M 9 7 L 9 18 L 11 18 L 11 7 Z M 13 7 L 13 18 L 15 18 L 15 7 Z M 13 7 "
                />
              </g>
            </svg>
          </b>
        </span>
      </Col>
    </Row>
  );
};

function getTanggal(date) {
  date = new Date(date);
  let month = date.getMonth();
  month =
    month === 0
      ? "Jan"
      : month === 1
      ? "Feb"
      : month === 2
      ? "Mar"
      : month === 3
      ? "Apr"
      : month === 4
      ? "Mei"
      : month === 5
      ? "Jun"
      : month === 6
      ? "Jul"
      : month === 7
      ? "Agu"
      : month === 8
      ? "Sep"
      : month === 9
      ? "Okt"
      : month === 10
      ? "Nov"
      : month === 11
      ? "Des"
      : null;
  return `${date.getDate()} ${month} ${date.getFullYear()}`;
}

const InputHarianHistory = ({ data, handleEditClick, handleDeleteClick }) => {
  return (
    <Container>
      <Row
        className="text-center"
        style={{ margin: "20px 1px", fontSize: ".85em", color: "#444" }}
      >
        <Col xs="4" className="pr-1 pl-1">
          <b>{getTanggal(data.date)}</b>
        </Col>
        <Col xs="5">
          <b>No ternak {data.code}</b>
        </Col>
        <Col xs="3" className="d-flex justify-content-around align-items-start">
          {/* <Edit width="20" style={{marginTop:"0",display:'none'}} onClick={()=>handleEditClick({id:data.id, tanggal:data.date, noTernak:data.code})}/> */}
          <span className="ml-2">
            <b
              style={{ fontSize: "22px", lineHeight: ".9" }}
              onClick={() => handleDeleteClick(data.id)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24px"
                height="24px"
              >
                <g id="surface772249">
                  <path
                    style={{
                      stroke: "none",
                      fillRule: "nonzero",
                      fill: "rgb(90.588236%,29.803923%,23.529412%)",
                      fillOpacity: "1",
                    }}
                    d="M 10 2 L 9 3 L 4 3 L 4 5 L 5 5 L 5 20 C 5 20.523438 5.191406 21.054688 5.570312 21.429688 C 5.945312 21.808594 6.476562 22 7 22 L 17 22 C 17.523438 22 18.054688 21.808594 18.433594 21.429688 C 18.808594 21.054688 19 20.523438 19 20 L 19 5 L 20 5 L 20 3 L 15 3 L 14 2 Z M 7 5 L 17 5 L 17 20 L 7 20 Z M 9 7 L 9 18 L 11 18 L 11 7 Z M 13 7 L 13 18 L 15 18 L 15 7 Z M 13 7 "
                  />
                </g>
              </svg>
            </b>
          </span>
        </Col>
      </Row>
      <hr className="m-0" />
    </Container>
  );
};

const InputBobotTernakHistory = ({
  data,
  handleEditClick,
  handleDeleteClick,
}) => {
  return (
    <Container>
      <Row
        className="text-center"
        style={{ margin: "20px 1px", fontSize: ".85em", color: "#444" }}
      >
        <Col xs="4" className="pr-1 pl-1">
          <b>{getTanggal(data.date)}</b>
        </Col>
        <Col xs="5" style={{ marginTop: "-.8em" }}>
          <p className="mb-0">
            <b>No ternak {data.code}</b>
          </p>
          <p className="mb-0">{data.bobot} Kg</p>
        </Col>
        {/*d-flex justify-content-around align-items-start*/}
        <Col xs="3">
          {/* <Edit width="20" style={{marginTop:"0"}} onClick={()=>handleEditClick({id:data.id, tanggal:data.tanggal, noTernak:data.noTernak, bobot:data.bobot})}/> */}
          <span className="ml-2">
            <b
              style={{ fontSize: "22px", lineHeight: ".9" }}
              onClick={() => handleDeleteClick(data.id)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24px"
                height="24px"
              >
                <g id="surface772249">
                  <path
                    style={{
                      stroke: "none",
                      fillRule: "nonzero",
                      fill: "rgb(90.588236%,29.803923%,23.529412%)",
                      fillOpacity: "1",
                    }}
                    d="M 10 2 L 9 3 L 4 3 L 4 5 L 5 5 L 5 20 C 5 20.523438 5.191406 21.054688 5.570312 21.429688 C 5.945312 21.808594 6.476562 22 7 22 L 17 22 C 17.523438 22 18.054688 21.808594 18.433594 21.429688 C 18.808594 21.054688 19 20.523438 19 20 L 19 5 L 20 5 L 20 3 L 15 3 L 14 2 Z M 7 5 L 17 5 L 17 20 L 7 20 Z M 9 7 L 9 18 L 11 18 L 11 7 Z M 13 7 L 13 18 L 15 18 L 15 7 Z M 13 7 "
                  />
                </g>
              </svg>
            </b>
          </span>
        </Col>
      </Row>
      <hr className="m-0" />
    </Container>
  );
};

const InputPerkawinanHistory = ({
  data,
  handleEditClick,
  handleDeleteClick,
}) => {
  return (
    <Container>
      <Row
        className="text-center"
        style={{ margin: "20px 1px", fontSize: ".85em", color: "#444" }}
      >
        <Col xs="4" className="pr-1 pl-1">
          <b>{getTanggal(data.date)}</b>
        </Col>
        <Col xs="5" style={{ marginTop: "-.6em" }}>
          <p className="mb-0">Nomor Betina: {data.betina}</p>
          <p className="mb-0">Nomor Jantan: {data.jantan}</p>
        </Col>
        <Col xs="3">
          {/* <Edit width="20" style={{marginTop:"0"}} onClick={()=>handleEditClick({id:data.id, tanggal:data.tanggal, noTernakJantan:data.noTernakJantan, noTernakBetina:data.noTernakBetina})}/> */}
          <span className="ml-2">
            <b
              style={{ fontSize: "22px", lineHeight: ".9" }}
              onClick={() => handleDeleteClick(data.id)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24px"
                height="24px"
              >
                <g id="surface772249">
                  <path
                    style={{
                      stroke: "none",
                      fillRule: "nonzero",
                      fill: "rgb(90.588236%,29.803923%,23.529412%)",
                      fillOpacity: "1",
                    }}
                    d="M 10 2 L 9 3 L 4 3 L 4 5 L 5 5 L 5 20 C 5 20.523438 5.191406 21.054688 5.570312 21.429688 C 5.945312 21.808594 6.476562 22 7 22 L 17 22 C 17.523438 22 18.054688 21.808594 18.433594 21.429688 C 18.808594 21.054688 19 20.523438 19 20 L 19 5 L 20 5 L 20 3 L 15 3 L 14 2 Z M 7 5 L 17 5 L 17 20 L 7 20 Z M 9 7 L 9 18 L 11 18 L 11 7 Z M 13 7 L 13 18 L 15 18 L 15 7 Z M 13 7 "
                  />
                </g>
              </svg>
            </b>
          </span>
        </Col>
      </Row>
      <hr className="m-0" />
    </Container>
  );
};

const InputKelahiranHistory = ({
  data,
  handleEditClick,
  handleDeleteClick,
}) => {
  return (
    <Container>
      <Row
        className="text-center"
        style={{
          margin: "20px 1px",
          fontSize: ".85em",
          color: "#444",
          marginTop: "25px",
        }}
      >
        <Col xs="4" className="pr-1 pl-1" style={{ marginTop: "-1.2em" }}>
          <p className="mb-0">{getTanggal(data.date)}</p>
          <p className="mb-0">No cempe {data.cempe}</p>
          <p className="mb-0">Bobot: {data.bobot} Kg</p>
        </Col>
        <Col xs="5" style={{ marginTop: "-.6em" }}>
          <p className="mb-0">Nomor Betina: {data.betina}</p>
          <p className="mb-0">Nomor Jantan: {data.jantan}</p>
        </Col>
        <Col xs="3">
          {/* <Edit width="20" style={{marginTop:"0"}} onClick={()=>handleEditClick({
						id:data.id, 
						tanggalLahir:data.tanggalLahir, 
						noCempe:data.noCempe,
						noInduk:data.noInduk, 
						noJantan:data.noJantan,
						bobotLahir:data.bobotLahir
					})}/> */}
          <span className="ml-2">
            <b
              style={{ fontSize: "22px", lineHeight: ".9" }}
              onClick={() => handleDeleteClick(data.id)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24px"
                height="24px"
              >
                <g id="surface772249">
                  <path
                    style={{
                      stroke: "none",
                      fillRule: "nonzero",
                      fill: "rgb(90.588236%,29.803923%,23.529412%)",
                      fillOpacity: "1",
                    }}
                    d="M 10 2 L 9 3 L 4 3 L 4 5 L 5 5 L 5 20 C 5 20.523438 5.191406 21.054688 5.570312 21.429688 C 5.945312 21.808594 6.476562 22 7 22 L 17 22 C 17.523438 22 18.054688 21.808594 18.433594 21.429688 C 18.808594 21.054688 19 20.523438 19 20 L 19 5 L 20 5 L 20 3 L 15 3 L 14 2 Z M 7 5 L 17 5 L 17 20 L 7 20 Z M 9 7 L 9 18 L 11 18 L 11 7 Z M 13 7 L 13 18 L 15 18 L 15 7 Z M 13 7 "
                  />
                </g>
              </svg>
            </b>
          </span>
        </Col>
      </Row>
      <hr className="m-0" />
    </Container>
  );
};

export {
  History,
  History2,
  InputHarianHistory,
  InputBobotTernakHistory,
  InputPerkawinanHistory,
  InputKelahiranHistory,
};
