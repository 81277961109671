import {Col} from 'reactstrap';
import {Link} from 'react-router-dom';
import './MenuItems.css';

const MenuItem = props => {
	return(
		<Col xs="3"  onClick={props.onClick} className="pl-2 pr-2">
			<Link to={props.to||'#!'} style={{color:props.textColor}}>
				<div className="logo" style={{backgroundColor: "rgb(239 239 239)", display: "block", margin: "20px 0 5px", width: "100%", height:"70px", borderRadius:"20px", border:"3px solid #363636", padding:'5px' }}>
					<img src={props.img} alt={props.img} width="85%" style={{maxWidth:'43px'}}/></div>
				<p className="menuText" style={{fontSize:'.8em'}}>{props.text}</p>
			</Link>
		</Col>
	)
}

const MainButton = props => {
	return(
		<div>
			<button className="main-button" onClick={props.onClick} style={{marginLeft:'auto',marginRight:'auto',display:'block'}}>{props.text}</button>
		</div>
	)
}

export {
	MenuItem,
	MainButton
};
