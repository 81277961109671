import moment from 'moment';
import {Component} from 'react';
import {Container, Row, Col, Form, FormGroup, Label, Input, InputGroupAddon, InputGroupText, InputGroup, CustomInput} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import InputFileImage from '../../component/InputFileImage/InputFileImage';
import siteConfig from '../../config/config'

import token from '../../helper/token';
import {handleResponse} from '../../helper/SinatriaHelper';

class InputTernak extends Component{
	constructor(props){
		super(props)
		this.state = {
			formData: {
                date: moment().format('YYYY-MM-DD')
            },
			gambarTernak: {},
            photo_0: null,
            photo_1: null,
            photo_2: null,
            photo_3: null,
		}
        this.inputKesehatan = ['Mulut','Hidung','Mata','Telinga','Kulit','Bulu','Pernafasan','Perut','Anus','Kuku'];
        this.jenisTernak = [
            'Domba Garut',
            'Domba Ekor Tipis',
            'Domba Ekor Gemuk',
            'Texel',
            'Merino',
            'Kompas Agrinak',
            'Merino Garut',
            'Sayur',
            'Dombos'
        ];
        this.statusFisiologis = [
            'Pejantan',
            'Induk Bunting',
            'Induk Menyusui',
            'Cempe Jantan',
            'Cempe Betina',
            'Lepas Sapih Jantan',
            'Lepas Sapih Betina',
            'Dara',
            'Afkir'
        ];
		this.handleData = this.handleData.bind(this);
	}

	handleData = (e) => {
		let name = e.target.name
		let value = e.target.value
		this.setState({
			formData:{
				...this.state.formData,
				[name]:value
			}
		},()=>{
			// console.log(this.state.formData)
		})
	}
    handleSwitch = (e) => {
		let name = e.target.name
		let value = e.target.checked
		this.setState({
			formData:{
				...this.state.formData,
				[name]:value
			}
		},()=>{
			console.log(this.state.formData)
		})
    }
	handleImage = (e) => {
        console.log(e.target)
		e.target.parentElement.style.maxHeight = 'fit-content'
		e.target.parentElement.style.overflow = 'visible'
		e.target.parentElement.style.boxShadow = 'none'
		e.target.parentElement.style.borderRadius = '0'
		this.setState({
			gambarTernak:{
				...this.state.gambarTernak,
				[e.target.id]:URL.createObjectURL( e.target.files[0] ),
			},
            [e.target.id]: e.target.files[0]
		}, () => {
			//Data belum dimasukkan ke formData.
			console.log(this.state)
		})
	}

    handleSubmit = (e)=>{
        e.preventDefault();
        let fd = new FormData();
        if(this.state.photo_0)
            fd.append('photo_0',this.state.photo_0);
        if(this.state.photo_1)
            fd.append('photo_1',this.state.photo_1);
        if(this.state.photo_2)
            fd.append('photo_2',this.state.photo_2);
        if(this.state.photo_3)
            fd.append('photo_3',this.state.photo_3);
        for ( var key in this.state.formData ) {
            fd.append(key, this.state.formData[key]);
        }
		fetch(`${siteConfig.api_url}ternak`,{
			method: "POST",
			headers: {
				"token": token.getToken(),
				// 'Content-Type': 'application/json'
			},
			// body: JSON.stringify(this.state.formData)
            body: fd
		})
		.then(handleResponse)
		.then( resJson => {
            console.log(resJson);
			this.props.history.push("/");
		})
    }

	render(){
		return(
            <Container>
                <h3 className="text-center mt-5">INPUT TERNAK</h3>
                <Form onSubmit={this.handleSubmit}>
                    <FormGroup>
                        <Label for="tanggal">Tanggal Masuk/Pembelian</Label>
                        <Input id="tanggal" name="date" type="date" onChange={this.handleData} value={this.state.formData.date} required/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="kodeTernak">Kode Ternak</Label>
                        <Input id="kodeTernak" name="code" type="text" onChange={this.handleData} required/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="jenisTernak">Jenis Ternak</Label>
                        <Input id="jenisTernak" name="jenis" type="select" onChange={this.handleData} required>
                            <option value="">Pilih</option>
                            {this.jenisTernak.map((item,index)=>{
                                return (<option value={item} key={index}>{item}</option>)
                            })}
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="jenisKelamin">Jenis Kelamin</Label>
                        <Input id="jenisKelamin" name="kelamin" type="select" onChange={this.handleData} required>
                            <option value="">Pilih</option>
                            <option value="Jantan">Jantan</option>
                            <option value="Betina">Betina</option>
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="tujuanProgram">Tujuan Program</Label>
                        <Input id="tujuanProgram" name="tujuan" type="select" onChange={this.handleData} required>
                            <option value="">Pilih</option>
                            <option value="Breeding">Breeding</option>
                            <option value="Fattening">Fattening</option>
                            <option value="Trading">Trading</option>
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label to="umur">Umur</Label>
                        <InputGroup>
                            <Input id="umur" name="umur" type="number" onChange={this.handleData} required/>
                            <InputGroupAddon addonType="append">
                                <InputGroupText>Bulan</InputGroupText>
                            </InputGroupAddon>
                        </InputGroup>
                    </FormGroup>
                    <FormGroup>
                        <Label for="statusTernak">Status Ternak</Label>
                        <Input id="statusTernak" name="status" type="select" onChange={this.handleData} required>
                            <option value="">Pilih</option>
                            {this.statusFisiologis.map((item,index)=>{
                                return (<option value={item} key={index}>{item}</option>)
                            })}
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="gambarTernak">Foto</Label>
                        <Container>
                            <Row>
                                <InputFileImage onChange={this.handleImage} id="0" imgSrc={this.state.gambarTernak.photo_0} />
                                <InputFileImage onChange={this.handleImage} id="1" imgSrc={this.state.gambarTernak.photo_1} />
                                <InputFileImage onChange={this.handleImage} id="2" imgSrc={this.state.gambarTernak.photo_2} />
                                <InputFileImage onChange={this.handleImage} id="3" imgSrc={this.state.gambarTernak.photo_3} />
                            </Row>
                        </Container>
                    </FormGroup>
                    <FormGroup>
                        <Label to="hargaBeli">Harga Beli</Label>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>Rp. </InputGroupText>
                            </InputGroupAddon>
                            <Input id="hargaBeli" name="harga_beli" type="number" onChange={this.handleData} required/>
                        </InputGroup>
                    </FormGroup>
                    <Row>
                        <Col xs="6">
                            <Label to="namaSupplier">Nama Supplier</Label>
                            <Input id="namaSupplier" name="nama_supplier" type="text" onChange={this.handleData} required />
                        </Col>
                        <Col xs="6">
                            <Label to="kontakSupplier">Kontak Supplier</Label>
                            <Input id="kontakSupplier" name="kontak_supplier" type="number" onChange={this.handleData} required />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="6">
                            <Label to="bobotBadan">Bobot Badan</Label>
                            <InputGroup>
                                <Input id="bobotBadan" name="bobot" type="number" onChange={this.handleData} required />
                                <InputGroupAddon addonType="append">
                                    <InputGroupText>kg</InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                        </Col>
                        <Col xs="6">
                            <Label to="lingkarDada">Lingkar Dada</Label>
                            <InputGroup>
                                <Input id="lingkarDada" name="lingkar_dada" type="number" onChange={this.handleData} required />
                                <InputGroupAddon addonType="append">
                                    <InputGroupText>cm</InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="6">
                            <Label to="panjangBadan">Panjang Badan</Label>
                            <InputGroup>
                                <Input id="panjangBadan" name="panjang" type="number" onChange={this.handleData} required />
                                <InputGroupAddon addonType="append">
                                    <InputGroupText>cm</InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                        </Col>
                        <Col xs="6">
                            <Label to="tinggiBadan">Tinggi Badan</Label>
                            <InputGroup>
                                <Input id="tinggiBadan" name="tinggi" type="number" onChange={this.handleData} required />
                                <InputGroupAddon addonType="append">
                                    <InputGroupText>cm</InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                        </Col>
                    </Row>
                    <br/>
                    <strong>Kesehatan Ternak</strong>
                    {this.inputKesehatan.map((item,index)=>{
                        return (<FormGroup key={index}>
                            <CustomInput type="switch" name={`${item.toLowerCase()}_sehat`} label={`${item} Sehat`} id={`${item.toLowerCase()}_sehat`} onChange={this.handleSwitch} />
                        </FormGroup>)
                    })}
                    <FormGroup>
                        <button className="btn btn-success btn-block">SIMPAN</button>
                    </FormGroup>
                </Form>
            </Container>
		)
	}
}

export default withRouter(InputTernak)