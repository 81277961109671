import {Component, Fragment} from 'react';
import { Link, withRouter } from 'react-router-dom';
import {Container, Row, Col} from 'reactstrap';

import {MenuItem} from '../../component/MenuItems/MenuItems';
import {History} from '../../component/History/History';

import InputHarian from '../../form/InputHarian/InputHarian';
import InputKesehatanHarian from '../../form/InputKesehatanHarian/InputKesehatanHarian';
import InputBobotTernak from '../../form/InputBobotTernak/InputBobotTernak';
import InputPerkawinan from '../../form/InputPerkawinan/InputPerkawinan';
import InputKelahiran from '../../form/InputKelahiran/InputKelahiran';
import InputTreatmentTernak from '../../form/InputTreatmentTernak/InputTreatmentTernak';

// import {formatRupiah,handleResponse} from '../../helper/SinatriaHelper';
import {formatRupiah} from '../../helper/SinatriaHelper';
// import token from '../../helper/token';
// import siteConfig from '../../config/config';
import getData from '../../hooks/getData';

class Dashboard extends Component{
	state = {
		totalSales: 0,
		historys: [],
		isActiveInputTernak: false,
		isActiveInputHarian: false,
		isActiveInputKesehatanHarian: false,
		isActiveInputBobotTernak: false,
		isActiveInputPerkawinan: false,
		isActiveInputKelahiran: false,
		isActiveInputTreatmentTernak: false
	}

  refreshHistory = () => {
		//history
		getData('GET', 'treatment').then(resJson => {
			this.setState({
				historys: resJson.data
			})
		})

		// fetch(`${siteConfig.api_url}treatment`,{
		// 	method: "GET",
		// 	headers: {"token": token.getToken()}
		// })
		// .then(handleResponse)
		// .then( resJson => {
		// 	this.setState({
		// 		historys: resJson.data
		// 	})
		// })
	}

	componentDidMount(){
		this.refreshHistory();
		// console.log('dashboard token -> ' + token.getToken());
		getData('GET', 'sales/report?startdate=2000-01-01').then(resJson => {
			this.setState({
				totalSales: resJson.data.total||0
			})
		})

		// const urlFetch = fetch(`${siteConfig.api_url}sales/report?startdate=2000-01-01`,{
		// 	method: "GET",
		// 	headers: {"token": token.getToken()}
		//   })
		// urlFetch
		// .then(handleResponse)
		// .then( resJson => {
		// 	console.log(resJson);
		// 	this.setState({
		// 		totalSales: resJson.data.total||0
		// 	})
		// })
	}

	scrollToTop(){
		document.body.scrollTop = 0; // For Safari
		document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
	}
	
	handleInputHarian = () => {
		this.setState({ isActiveInputHarian: !this.state.isActiveInputHarian })
	}
	handleInputKesehatanHarian = () => {
		this.setState({ isActiveInputKesehatanHarian: !this.state.isActiveInputKesehatanHarian })
	}
	handleInputBobotTernak = () => {
		this.setState({ isActiveInputBobotTernak: !this.state.isActiveInputBobotTernak })
	}
	handleInputPerkawinan = () => {
		this.setState({ isActiveInputPerkawinan: !this.state.isActiveInputPerkawinan })
	}
	handleInputKelahiran = () => {
		this.setState({ isActiveInputKelahiran: !this.state.isActiveInputKelahiran })
	}
	handleInputTreatmentTernak = () => {
		this.setState({ isActiveInputTreatmentTernak: !this.state.isActiveInputTreatmentTernak });
		this.scrollToTop();
	}

	handleTreatmentSubmit = (params) => {
		getData('POST', 'treatment', { 
			body: JSON.stringify(params) 
		}).then(() => {
			this.refreshHistory();
		})

		// fetch(`${siteConfig.api_url}treatment`,{
		// 	method: "POST",
		// 	headers: {
		// 		"token": token.getToken(),
		// 		'Content-Type': 'application/json'
		// 	},
		// 	body: JSON.stringify(params)
		// })
		// .then(handleResponse)
		// .then( resJson => {
		// 	this.refreshHistory();
		// })
	}

	handleDeleteClick = (id) => {
		getData('DELETE', `treatment/${id}`).then(() => {
			this.refreshHistory();
		})

		// fetch(`${siteConfig.api_url}treatment/${id}`,{
		// 	method: "DELETE",
		// 	headers: {
		// 		"token": token.getToken(),
		// 		'Content-Type': 'application/json'
		// 	}
		// })
		// .then(handleResponse)
		// .then( resJson => {
    //         this.refreshHistory();
		// }).catch((err)=>{
		// 	console.log(err);
		// })
	}

	render(){
		return(
			<Fragment>
			{this.state.isActiveInputHarian?<InputHarian close={this.handleInputHarian} menu='tidakNafsuMakan' />:null}
			{this.state.isActiveInputKesehatanHarian?<InputKesehatanHarian close={this.handleInputKesehatanHarian} />:null}
			{this.state.isActiveInputBobotTernak?<InputBobotTernak close={this.handleInputBobotTernak} />:null}
			{this.state.isActiveInputPerkawinan?<InputPerkawinan close={this.handleInputPerkawinan} />: null}
			{this.state.isActiveInputKelahiran?<InputKelahiran close={this.handleInputKelahiran} />: null}
			{this.state.isActiveInputTreatmentTernak?<InputTreatmentTernak close={this.handleInputTreatmentTernak} handleSubmit={this.handleTreatmentSubmit} />: null}
			<main>
				<Container className="second-header menu-list text-white pt-4">
					{/* ===== Text Second Menu =====*/}
					<h2>{formatRupiah(this.state.totalSales)}</h2>
					<p className="d-block">Total Penjualan</p>
					<Row className="text-center m-0">

						{/* ===== Second Menu =====*/}
						<MenuItem text="Penjualan" to="/penjualan" textColor="#fff" img="/img/menu-icon/v2/ico-penjualan.png" />
						<MenuItem text="Pembelian" to="/pembelian" textColor="#fff" img="/img/menu-icon/v2/ico-pembelian.png" />
						<MenuItem text="Pengeluaran" to="/pengeluaran" textColor="#fff" img="/img/menu-icon/v2/ico-pengeluaran.png" />
						<MenuItem text="Laporan" to="/report" textColor="#fff" img="/img/menu-icon/v2/ico-laporan farm.png" />
					</Row>
				</Container>

				<Container className="menu-list text-center text-grey logo-grey">
					<Row className="m-0">
						{/* ===== Main Menu List Atas =====*/}

						<MenuItem text="Input Ternak" textColor="#333" to="/input-ternak" img="/img/menu-icon/v2/ico-input ternak.png" />
						<MenuItem text="Input Pakan Harian" textColor="#333" to="/pakan-harian" img="/img/menu-icon/v2/ico-input pakan ternak.png" />
						<MenuItem text="Input Kesehatan Harian" textColor="#333" to="/kesehatan-harian" img="/img/menu-icon/v2/ico-input kesehatan ternak.png" />
						<MenuItem text="Input Bobot Ternak" textColor="#333" to="/bobot-harian" img="/img/menu-icon/v2/ico-input bobot ternak.png" />
					</Row>
				</Container>

				<div className="box-bawah">
					<div className="menu-list text-center text-grey logo-grey pt-3">
						<Container>
							<Row className="m-0">
								{/* ===== Main Menu List Bawah =====*/}
								<MenuItem text="Input Perkawinan" textColor="#333" to="/perkawinan" img="/img/menu-icon/v2/ico-perkawinan ternak.png" />
								<MenuItem text="Input Kelahiran" textColor="#333" to="/kelahiran" img="/img/menu-icon/v2/ico-kelahiran.png"/>
								<MenuItem text="Input Treatment" textColor="#333" to="/treatment" img="/img/menu-icon/v2/ico-input treatment ternak.png" />
								<MenuItem text="Data Ternak" textColor="#333" to="/data-ternak" img="/img/menu-icon/v2/ico-data ternak.png"/>
							</Row>
						</Container>
					</div>
					<section className="treatment mt-3 mb-3 ">
						<Container>
							<Row className="mt-4">
								{/* ===== Riwayat List =====*/}
								<Col xs="8">
									<h5 style={{fontSize:'1.1rem'}}>Riwayat Treatment Ternak</h5>
								</Col>
								<Col xs="4">
									<Link to="/treatment">Lihat Semua</Link>
								</Col>
							</Row>
							{/* ===== Riwayat List =====*/}
							{this.state.historys.length>=1?(
								this.state.historys.map(history=>{
									return <History handleDeleteClick={() => this.handleDeleteClick(history.id)} key={history.id} tanggal={history.date} noternak={history.code} diagnosisBold={history.diagnosis} diagnosis={history.diagnosis} />
								})
							):(
								<div className="text-center">
									<strong>Belum Ada Data Riwayat Treatment</strong>
								</div>
							)}
						</Container>
					</section>
					<div className="space"></div>
				</div>
			</main>
			</Fragment>
		)
	}
}

export default withRouter(Dashboard)