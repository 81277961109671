import {Container, Row, Col, Form, FormGroup, Label, Input, Button} from 'reactstrap';
import {Component} from 'react';
import PopUp from '../../component/PopUp/PopUp';
import moment from 'moment'
import querystring from 'query-string';
import {formatRupiah, handleResponse} from '../../helper/SinatriaHelper'
import siteConfig from '../../config/config'
import token from '../../helper/token';

export default class TambahPenjualan extends Component {
	constructor(props) {
		super(props);
		this.state = {
			formData:{
				date: moment().format('YYYY-MM-DD'),
				price: '',
				quantity: 1,
				customCategory: ''
			},
			vendors: [],
			categories: [],
			inputCategoryDisplay: 'none'
		};
		this.handleData = this.handleData.bind(this);
	}

	componentDidMount(){
		fetch(`${siteConfig.api_url}vendor`,{
			method: "GET",
			headers: {"token": token.getToken()}
		})
		.then(handleResponse)
		.then( resJson => {
			this.setState({
				vendors: resJson.data
			})
		})
		
		//categories
		let params = {
			type: 'purchase'
		}
		fetch(`${siteConfig.api_url}category?${querystring.stringify(params)}`,{
			method: "GET",
			headers: {
				"token": token.getToken(),
				'Content-Type': 'application/json'
			}
		})
		.then(handleResponse)
		.then( resJson => {
			this.setState({
				categories: resJson.data
			})
		})
	}
	
	handleData = (e) => {
		let keys = e.target.name
		let value = e.target.value
		if(keys==='category'){
			if(value==='--lainnya'){
				this.setState({inputCategoryDisplay:'block'})
			}else{
				this.setState({inputCategoryDisplay:'none'})
			}
		}
		this.setState({
			formData:{
				...this.state.formData,
				[keys]: value,
			}
		})
	}
	submitData = (e) => {
		e.preventDefault();
		fetch(`${siteConfig.api_url}purchase`,{
			method: "POST",
			headers: {
				"token": token.getToken(),
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(this.state.formData)
		})
		.then(handleResponse)
		.then( resJson => {
			window.location.reload();
		})
	}
	render(){
		return(
			<PopUp onClick={this.props.close}>
				<Container>
					<h4 className="mt-5 mb-3">TAMBAH PEMBELIAN</h4>
					<Form onSubmit={this.submitData}>
					  <FormGroup>
				        <Label for="tanggalInput">Tanggal</Label>
				        <Input type="date" name="date" id="tanggalInput" onChange={this.handleData} value={this.state.formData.date}/>
				      </FormGroup>
				      <FormGroup>
				        <Label for="Namainput">Nama</Label>
				        <Input type="text" name="name" id="Namainput" onChange={this.handleData}/>
				      </FormGroup>
				      <FormGroup>
				        <Label for="kategoriPembelianInput">Kategori Pembelian</Label>
				        <Input type="select" name="category" id="kategoriPembelianInput" onChange={this.handleData}>
				          <option value="">Pilih</option>
							{this.state.categories.map(v=>{
							return <option value={v.id} key={v.id}>{v.name}</option>
							})}
						  <option value="--lainnya">Lainnya</option>
				        </Input>
						<br/>
						<Input type="text" name="customCategory" style={{display:this.state.inputCategoryDisplay}} placeholder="Input Kategori" onChange={this.handleData} value={this.state.formData.customCategory} />
				      </FormGroup>
				      <FormGroup>
				        <Label for="vendor">Supplier/Distributor</Label>
				        <Input type="text" name="vendor" id="vendor" onChange={this.handleData}/>
				      </FormGroup>
				      {/* <FormGroup>
				        <Label for="kategoriPembelianInput">Supplier/Distributor</Label>
				        <Input type="select" name="vendor" id="kategoriPembelianInput" onChange={this.handleData}>
				          <option value="">Pilih</option>
							{this.state.vendors.map(v=>{
							return <option value={v.id} key={v.id}>{v.name}</option>
							})}
				        </Input>
				      </FormGroup> */}
				      <FormGroup>
				      	<Row>
				      		<Col xs="4">
						        <Label for="jumlahInput">Jumlah</Label>
						        <Input type="text" name="quantity" id="jumlahInput" onChange={this.handleData} value={this.state.formData.quantity}/>
				      		</Col>
				      		<Col xs="8">
						        <Label for="hargaSatuanInput">Harga Satuan Pembelian</Label>
						        <Input type="text" name="price" id="hargaSatuanInput" onChange={this.handleData} value={this.state.formData.price}/>
				      		</Col>
				      	</Row>
				      </FormGroup>
				      <FormGroup>
					        <Label for="totalPembelian">Total Pembelian</Label>
					        <Input type="text" name="totalPembelian" id="totalPembelian" onChange={this.handleData} value={formatRupiah(this.state.formData.quantity * this.state.formData.price)} readOnly/>
				      </FormGroup>
				      <Button className="btn btn-success btn-block">TAMBAH PEMBELIAN</Button>
				    </Form>
				</Container>
			</PopUp>
		)
	}
}