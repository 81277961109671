import './PopUp.css';

const PopUp = ({children, onClick, style}) => {
	return(
		<div id='popup' style={style}>
				<h2 onClick={onClick} className="close">X</h2>
				{children}
		</div>
	)
}


export default PopUp;