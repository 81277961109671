import {Component, Fragment} from 'react';
import {Container, Row, Col, FormGroup, Label, Input, Form} from 'reactstrap';
import Search from '../../component/Search/Search';
import {InputHarianHistory} from '../../component/History/History';
import Filter from '../../form/Filter/Filter';
import './PakanHarian.css';

import siteConfig from '../../config/config';
import moment from 'moment';

import token from '../../helper/token';
import {handleResponse} from '../../helper/SinatriaHelper';

const menuTitle = [[
		'jumlahPakanKurang','Jumlah Pakan Kurang'
	],[
		'sisaPakanBanyak','Sisa Pakan Banyak'
	],[
		'tidakNafsuMakan','Tidak Nafsu Makan'
	]
]
export default class PakanHarian extends Component{
	constructor(props){
		super(props)
		this.state={
			formData: {
				date: moment().format('YYYY-MM-DD'),
				status: 'Tidak Nafsu Makan',
				data:{
					date: moment().format('YYYY-MM-DD'),
					noternak: null
				}
			},
			menuActive: 'tidakNafsuMakan',
			historyTitle: "Ternak "+menuTitle.find(data=>data[0]==='tidakNafsuMakan')[1],
			isActiveFilter: false,
			allHistory: [],
			historys: []
		}
	}

	refreshHistory = () => {
		//history
		fetch(`${siteConfig.api_url}pakan`,{
			method: "GET",
			headers: {"token": token.getToken()}
		})
		.then(handleResponse)
		.then( resJson => {
			this.setState({
				allHistory: resJson.data,
				historys: resJson.data.filter(history=>history.status===menuTitle.find(data=>data[0]===this.state.menuActive)[1])
			})
		})
	}

	componentDidMount(){
		this.refreshHistory();
	}

	isActiveFilterHandle = () => {
		this.setState({
			isActiveFilter: !this.state.isActiveFilter
		})
	}

	changeMenu = (menu) => {
		this.setState({
			menuActive: menu,
			historyTitle: "Ternak "+menuTitle.find(data=>data[0]===menu)[1],
	    	historys: this.state.allHistory.filter(history=>history.status===menuTitle.find(data=>data[0]===menu)[1]),
		})
		
		this.setState({
			formData:{
				...this.state.formData,
				status:menuTitle.find(data=>data[0]===menu)[1]
			}
		})
	}

	handleHistoryPatch = data => {
		this.setState({
			formData:{data}
		}, ()=>{
			console.log(this.state.formData)
		})
	}

	handleHistoryDelete = id => {
		fetch(`${siteConfig.api_url}pakan/${id}`,{
			method: "DELETE",
			headers: {
				"token": token.getToken(),
				'Content-Type': 'application/json'
			}
		})
		.then(handleResponse)
		.then( resJson => {
			this.refreshHistory();
		})
	}

	handleData = (e) => {
		let name = e.target.name
		let value = e.target.value
		this.setState({
			formData:{
				...this.state.formData,
				[name]:value
			}
		},()=>{
			console.log(this.state.formData)
		})
	}

	handleSubmit = (e) => {
		e.preventDefault();
		fetch(`${siteConfig.api_url}pakan`,{
			method: "POST",
			headers: {
				"token": token.getToken(),
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(this.state.formData)
		})
		.then(handleResponse)
		.then( resJson => {
			this.refreshHistory();
			this.setState({
				formData:{
					...this.state.formData,
					code:''
				}
			})
		})
	}

	render(){
		return(
			<Fragment>
				{this.state.isActiveFilter?<Filter close={this.isActiveFilterHandle} />:null}

                {/* === Navigation === */}
                <nav>
                    <Container>
                        <h4 className='mt-5'>INPUT PAKAN HARIAN</h4>
                        <Row className="text-center mr-1 ml-1 justify-content-center">
                            <hr/>
                            <Col xs={this.state.menuActive==='tidakNafsuMakan'?"4 pl-0 pr-0 nav active-ih":"4 pl-0 pr-0 nav"} onClick={()=>this.changeMenu('tidakNafsuMakan')}>
                                <p>Tidak Nafsu Makan</p>
                            </Col>
                            <Col xs={this.state.menuActive==='sisaPakanBanyak'?"4 pl-0 pr-0 nav active-ih":"4 pl-0 pr-0 nav"} onClick={()=>this.changeMenu('sisaPakanBanyak')}>
                                <p>Sisa Pakan Banyak</p>
                            </Col>
                            <Col xs={this.state.menuActive==='jumlahPakanKurang'?"4 pl-0 pr-0 nav active-ih":"4 pl-0 pr-0 nav"} onClick={()=>this.changeMenu('jumlahPakanKurang')}>
                                <p>Jumlah Pakan Kurang</p>
                            </Col>
                        </Row>
                    </Container>
                </nav>

                {/* === Form === */}
                <section id="input">
                    <Container>
						<Form onSubmit={this.handleSubmit}>
							<Row className="mt-3">
								<Col xs="6" className="pr-0">
									<FormGroup>
										<Label for="tanggal">Tanggal</Label>
										<Input bsSize="sm" id="tanggal" name="date" type="date" onChange={this.handleData} value={this.state.formData.date} required/>
									</FormGroup>
								</Col>
								<Col xs="3" className="pl-1 pr-0">
									<FormGroup>
										<Label for="nomorTernak">Nomor Ternak</Label>
										<Input bsSize="sm" id="nomorTernak" name="code" type="number" onChange={this.handleData} value={this.state.formData.code} required/>
									</FormGroup>
								</Col>
								<Col xs="2" className="pl-1">
									<FormGroup>
										<button style={{marginTop:'30px'}} className="btn btn-success btn-sm">SIMPAN</button>
									</FormGroup>
								</Col>
							</Row>
						</Form>
                    </Container>
                </section>

                {/* === History === */}
                <section id="history">
                    <Container>
                        <h3>{this.state.historyTitle}</h3>
                        <Search onClick={(this.isActiveFilterHandle)} />
                        {this.state.historys.map(history=>{
                            return <InputHarianHistory data={history} key={history.id} handleEditClick={data=>this.handleHistoryPatch(data)} handleDeleteClick={id=>this.handleHistoryDelete(id)}/>
                        })}
                    </Container>
                </section>
                
			</Fragment>
		)
	}
}