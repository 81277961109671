import {Component} from 'react';
import PopUp from '../../component/PopUp/PopUp';
import {Container, Form, FormGroup, Label, Input, Row} from 'reactstrap';
import InputFileImage from '../../component/InputFileImage/InputFileImage';

export default class InputKesehatanHarian extends Component{
	constructor(props){
		super(props)
		this.state = {
			formData: {},
			gambarTernak: {}
		}
	}
	handleData = (e) => {
		let name = e.target.name
		let value = e.target.value
		this.setState({
			formData:{
				...this.state.formData,
				[name]:value
			}
		},()=>{
			console.log(this.state.formData)
		})
	}
	dataSubmit = () => {
		console.log(this.state.formData)
		alert('data submitted')
	}
	handleImage = (e) => {
		e.target.parentElement.style.maxHeight = 'fit-content'
		e.target.parentElement.style.overflow = 'visible'
		e.target.parentElement.style.boxShadow = 'none'
		e.target.parentElement.style.borderRadius = '0'
		this.setState({
			gambarTernak:{
				...this.state.gambarTernak,
				[e.target.id]:URL.createObjectURL( e.target.files[0] )
			}
		}, () => {
			//Data belum dimasukkan ke formData.
			console.log(e.target.files[0])
		})
	}
	render(){
		return(
			<PopUp onClick={this.props.close}>
				<Container style={{backgroundColor:"#f6f6f6"}}>
					<h3 className="text-center mt-5">INPUT KESEHATAN HARIAN</h3>
					<Form>
						<FormGroup>
							<Label for="tanggal">Tanggal</Label>
							<Input id="tanggal" name="tanggal" type="date" onChange={this.handleData}/>
						</FormGroup>
						<FormGroup>
							<Label for="kodeTernak">Kode Ternak</Label>
							<Input id="kodeTernak" name="kodeTernak" type="text" onChange={this.handleData}/>
						</FormGroup>
						<FormGroup>
							<Label for="kesehatanMulut">Kesehatan Mulut</Label>
							<Input id="kesehatanMulut" name="kesehatanMulut" type="text" onChange={this.handleData}/>
						</FormGroup>
						<FormGroup>
							<Label for="kesehatanHidung">Kesehatan Hidung</Label>
							<Input id="kesehatanHidung" name="kesehatanHidung" type="text" onChange={this.handleData}/>
						</FormGroup>
						<FormGroup>
							<Label for="kesehatanMata">Kesehatan Mata</Label>
							<Input id="kesehatanMata" name="kesehatanMata" type="text" onChange={this.handleData}/>
						</FormGroup>
						<FormGroup>
							<Label for="kesehatanHidung">Kesehatan Hidung</Label>
							<Input id="kesehatanHidung" name="kesehatanHidung" type="text" onChange={this.handleData}/>
						</FormGroup>
						<FormGroup>
							<Label for="kesehatanMata">Kesehatan Mata</Label>
							<Input id="kesehatanMata" name="kesehatanMata" type="text" onChange={this.handleData}/>
						</FormGroup>
						<FormGroup>
							<Label for="kesehatanTelinga">Kesehatan Telinga</Label>
							<Input id="kesehatanTelinga" name="kesehatanTelinga" type="text" onChange={this.handleData}/>
						</FormGroup>
						<FormGroup>
							<Label for="kesehatanKulit">Kesehatan Kulit</Label>
							<Input id="kesehatanKulit" name="kesehatanKulit" type="text" onChange={this.handleData}/>
						</FormGroup>
						<FormGroup>
							<Label for="kesehatanBulu">Kesehatan Bulu</Label>
							<Input id="kesehatanBulu" name="kesehatanBulu" type="text" onChange={this.handleData}/>
						</FormGroup>
						<FormGroup>
							<Label for="kesehatanPernafasan">Kesehatan Pernafasan</Label>
							<Input id="kesehatanPernafasan" name="kesehatanPernafasan" type="text" onChange={this.handleData}/>
						</FormGroup>
						<FormGroup>
							<Label for="kesehatanPerut">Kesehatan Perut</Label>
							<Input id="kesehatanPerut" name="kesehatanPerut" type="text" onChange={this.handleData}/>
						</FormGroup>
						<FormGroup>
							<Label for="kesehatanAnus">Kesehatan Anus</Label>
							<Input id="kesehatanAnus" name="kesehatanAnus" type="text" onChange={this.handleData}/>
						</FormGroup>
						<FormGroup>
							<Label for="kesehatanKuku">Kesehatan Kuku</Label>
							<Input id="kesehatanKuku" name="kesehatanKuku" type="text" onChange={this.handleData}/>
						</FormGroup>
						<FormGroup>
							<Label for="kesehatanSehat">Keterangan Sehat</Label>
							<Input id="kesehatanSehat" name="kesehatanSehat" type="select" onChange={this.handleData}>
								<option value="">pilih</option>
								<option value="Sehat">Sehat</option>
								<option value="Sakit">Sakit</option>
							</Input>
						</FormGroup>
						<FormGroup>
							<Label for="deskripsi">Deskriptsi</Label>
							<Input id="deskripsi" name="deskripsi" type="text" onChange={this.handleData}/>
						</FormGroup>
						<FormGroup>
							<Label for="gambarTernak">Foto</Label>
							<Container>
								<Row>
									<InputFileImage onChange={this.handleImage} id="0" imgSrc={this.state.gambarTernak.inputImg0} />
									<InputFileImage onChange={this.handleImage} id="1" imgSrc={this.state.gambarTernak.inputImg1} />
									<InputFileImage onChange={this.handleImage} id="2" imgSrc={this.state.gambarTernak.inputImg2} />
									<InputFileImage onChange={this.handleImage} id="3" imgSrc={this.state.gambarTernak.inputImg3} />
									<InputFileImage onChange={this.handleImage} id="4" imgSrc={this.state.gambarTernak.inputImg4} />
									<InputFileImage onChange={this.handleImage} id="5" imgSrc={this.state.gambarTernak.inputImg5} />
								</Row>
							</Container>
						</FormGroup>
						<FormGroup>
							<button className="btn btn-success btn-block" onClick={this.dataSubmit}>INPUT KESEHATAN</button>
						</FormGroup>
					</Form>
				</Container>
			</PopUp>
		)
	}
}