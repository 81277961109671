import {Component} from 'react';
import PopUp from '../../component/PopUp/PopUp';
import {Container, Row, Col, Form, FormGroup, Label, Input, InputGroupAddon, InputGroupText, InputGroup} from 'reactstrap';
import Search from '../../component/Search/Search';
import {InputBobotTernakHistory} from '../../component/History/History';
import Filter from '../Filter/Filter';

export default class InputBobotTernak extends Component{
	constructor(props){
		super(props)
		this.state = {
			formData: {
				data: {
					tanggal: new Date(),
					noTernak: null,
					bobot: null
				}
			},
			historys:[
				{id:1, tanggal:new Date('04/20/2021'), noTernak:"7214", bobot:57},
				{id:2, tanggal:new Date('04/22/2021'), noTernak:"7213", bobot:58},
				{id:3, tanggal:new Date('04/24/2021'), noTernak:"7212", bobot:59},
				{id:4, tanggal:new Date('04/26/2021'), noTernak:"7211", bobot:60},
				{id:5, tanggal:new Date('04/28/2021'), noTernak:"7210", bobot:61}
			]
		}
	}
	isActiveFilterHandle = () => {
		this.setState({
			isActiveFilter: !this.state.isActiveFilter
		})
	}
	handleData = (e) => {
		let name = e.target.name
		let value = e.target.value
		this.setState({
			formData:{
				...this.state.formData,
				[name]:value
			}
		},()=>{
			console.log(this.state.formData)
		})
	}
	handleHistoryPatch = data => {
		this.setState({
			formData:{data:data}
		}, ()=>{
			console.log(this.state.formData)
		})
	}
	handleHistoryDelete = id => {
		alert('hapus '+id)
	}
	dataSubmit = () => {
		console.log(this.state.formData)
		alert('data submitted')
	}
	render(){
		return(
			<PopUp onClick={this.props.close}>
				{this.state.isActiveFilter?<Filter close={this.isActiveFilterHandle} />:null}
				{/* ===== Section Input ===== */}
				<Container style={{backgroundColor:"#f6f6f6"}}>
					<h3 className="text-center mt-5">INPUT BOBOT TERNAK</h3>
					<Form>
						<FormGroup>
							<Label for="tanggal">Tanggal Penimbangan</Label>
							<Input id="tanggal" name="tanggal" type="date" onChange={this.handleData} value={this.state.formData.data.tanggal.toJSON().split('T')[0]}/>
						</FormGroup>
						<FormGroup>
							<Container>
								<Row className="mt-3">
									<Col xs="5" className="pr-1 pl-0">
										<FormGroup>
											<Label for="kodeTernak">Kode Ternak</Label>
											<Input bsSize="sm" id="kodeTernak" name="kodeTernak" type="text" onChange={this.handleData} value={this.state.formData.data.noTernak}/>
										</FormGroup>
									</Col>
									<Col xs="5" className="pl-1 pr-1">
										<FormGroup>
											<Label for="bobot">Bobot</Label>
											<InputGroup size="sm">
												<Input bsSize="sm" id="bobot" name="bobot" type="number" onChange={this.handleData} value={this.state.formData.data.bobot}/>
										        <InputGroupAddon addonType="append">
										        	<InputGroupText>Kg</InputGroupText>
										        </InputGroupAddon>
										    </InputGroup>
										</FormGroup>
									</Col>
									<Col xs="2" className="pl-1">
										<FormGroup>
											<button style={{marginTop:'32px'}} className="btn btn-success btn-sm">INPUT</button>
										</FormGroup>
									</Col>
								</Row>
							</Container>
						</FormGroup>
					</Form>
				</Container>

				{/* ===== History Section ===== */}
				<section>
					<h4><b>Riwayat Bobot Ternak</b></h4>
					<Search onClick={(this.isActiveFilterHandle)} />
					{this.state.historys.map(history=>{
						return <InputBobotTernakHistory data={history} handleEditClick={data=>this.handleHistoryPatch(data)} handleDeleteClick={id=>this.handleHistoryDelete(id)}/>
					})}
				</section>

			</PopUp>
		)
	}
}