import {Col} from 'reactstrap';

const InputFileImage = ({id, onChange, imgSrc}) => {
	let clickFile = () => {
		document.getElementById('photo_'+id).click()
	}
	return(
		<Col xs="6">
			<div style={{overflow:'hidden', maxHeight:'100px', marginTop:'10px', boxShadow:'2px 4px 5px grey', borderRadius:'15px', width: 'fit-content'}} onClick={clickFile}>
				<img src={imgSrc || '/img/inputimagedefault.png'} alt='foto' style={{width:'100px', margin:'auto'}} id={'image'+id} />
		        <input type="file" id={"photo_"+id} style={{visibility:'hidden', width:'0'}} onChange={onChange} name={'image-'+id} accept="image/*"/>
			</div>
		</Col>
	)
}

export default InputFileImage;