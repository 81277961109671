import {Component} from 'react';
import { withRouter, Link } from 'react-router-dom';
import {Container, Form, FormGroup, Label, Input, Button, Alert} from 'reactstrap';
import './SplashScreen.css';
import SiteConfig from '../../config/config';
import token from '../../helper/token';
import queryString from 'query-string'

let styleBg = {
  backgroundImage: 'url("/img/spashscreen-bg.png")',
}
// let styleLink = {
//   display: 'inline',
//   color: 'blue'
// }
let styleLogo = {
  width: '100%'
}

class SplashScreen extends Component {
	constructor(props){
		super(props)
		this.state = {
      phone: '',
      password: '',
      submitted: false,
      isSuccess: false,
      isError: false,
      errMessage: ''
		}

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
	}

  componentDidMount(){
    const query = queryString.parse(this.props.location.search)
    if(query.register==='success'){
      this.setState({isSuccess:true})
    }
  }
  
  handleChange(e){
    const {name,value} = e.target;
    this.setState({[name]:value});
  }

  async handleSubmit(e){
    e.preventDefault();
    let response = await fetch(`${SiteConfig.api_url}user/login`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        phone: this.state.phone,
        password: this.state.password
      })
    });

    let result = await response.json();
    if(result.code===200){
      if(result.data.token) {
        console.log('token -> ' + result.data.token);
        token.setToken(result.data.token);
        this.props.history.push("/dashboard");
      }else{
        console.log(JSON.stringify(result.data));
        this.setState({
          isError: true,
          errMessage: 'Token error'
        })
      }
    }
    if(result.code===404){
      this.setState({
        isError: true,
        errMessage: 'Nomor HP/password tidak cocok'
      })
    }
  }

  render(){
    return (
      <div style={styleBg}>
        <h2 className="bismillah">Bismillah</h2>
        <Container className="">
          <div className="text-center">
            <img src="/img/sinatriafarm-logo.png" style={styleLogo} alt="sinatriafarm-logo" />
            <div className="tagLineAtas">
              <h2>Bersama Kita Wujudkan</h2>
              <h2>Kedaulatan Pangan</h2>
              <h2>Menuju Indonesia</h2>
              <h2>5 Besar Dunia</h2>
            </div>
          </div>
            <Alert color="success" isOpen={this.state.isSuccess} toggle={()=> this.setState({isSuccess: false})}>
                Pendaftaran berhasil. Silakan login
            </Alert>
            <Alert color="danger" isOpen={this.state.isError} toggle={()=> this.setState({isError: false})}>
               {this.state.errMessage}
            </Alert>
          <Form className="m-4" onSubmit={this.handleSubmit} id="form-login">
            <FormGroup>
              <Label for="no-handphone">no handphone</Label>
              <Input type="text" name="phone" id="no-handphone" value={this.state.phone} onChange={this.handleChange} />
            </FormGroup>
            <FormGroup>
              <Label for="password">password</Label>
              <Input type="password" name="password" id="password" value={this.state.password} onChange={this.handleChange} />
            </FormGroup>
            <FormGroup className="mt-4">
              <Button color="success" size="md" className="form-control">Masuk</Button>
            </FormGroup>
            <FormGroup className="mt-3 text-center">
              <Link to="/register" size="md" className="btn btn-primary form-control">Daftar</Link>
              {/* <FormText color="muted">lupa password?<a href="#" style={styleLink}> RESET</a></FormText> */}
            </FormGroup>
            <p className="test-mute text-center mt-4">lupa password? <a href="https://api.whatsapp.com/send?phone=6281393881322&text=Halo,%20saya%20akun%20nomor%20*.......*%20ingin%20melakukan%20reset%20password%0A%0APassword%20baru%20yang%20saya%20inginkan%20%0A*........*%20">RESET</a></p>
          </Form>
          <div className="tagLineBawah">
            <h2>LAPORAN BERKALA</h2>
            <h2>KEGIATAN, TERNAK DAN</h2>
            <h2>PEMBUKUAN</h2>
          </div>
        </Container>
      </div>
    );
  }
}

export default withRouter(SplashScreen)