import { Link } from 'react-router-dom';
import {Container, Row, Col, Button} from 'reactstrap';
import './Card.css';
import siteConfig from '../../config/config'

const TernakCard = ({data, cardStatusClick, cardDetailClick, cardDeleteClick}) => {
	return(
		<Container className="ternak-card">
			<Row>
		    	<Col xs='4'>
					<div className='box-img mt-3'>
						<img src={data.img0?siteConfig.img_url+data.img0:"/img/inputimagedefault.png"} alt="" style={{maxWidth:'90px',borderRadius:'10px'}} />
					</div>
		    	</Col>
		    	<Col className='mt-4' xs='5'>
		    		<p><b>{data.code}</b></p>
					<p>{data.kelamin}</p>
					<p>{data.jenis}</p>
					<p>{data.tujuan}</p>
		    	</Col>
				<Col xs='3'>
					<button onClick={()=>cardDetailClick(data.id)} className="btn btn-sm btn-secondary d-block w-100 mt-2">Detail</button>
					<button onClick={()=>cardStatusClick(data)}
						className={"btn btn-sm d-block w-100 mt-2 "+(data.currentStatus||"dibeli")} 
						style={{textTransform:'capitalize', color:'#fff'}}>{(data.currentStatus||"dibeli")}</button>
					<button onClick={()=>cardDeleteClick(data.id)} className="btn btn-sm btn-danger d-block w-100 mt-2">Delete</button>
				</Col>
				<hr/>
			</Row>
		</Container>
	)
}

const AlumniCard = ({data, onDeleteClick}) => {
	// console.log(data);
	return(
		<Container className="alumni-card">
			<Row>
				<Col xs="7">
					<p><b>{data.name}</b></p>
					<p>{data.phone}</p>
					<p>{data.kab}</p>
					<p><b>Alamat Farm: </b>{data.address}</p>
				</Col>
				<Col xs="5">
					<Link to={`/alumni/${data.id}/profile`} className="btn btn-sm mt-2 w-100 btn-warning">Edit Profile</Link>
					<Link to={`/alumni/${data.id}/report`} className="btn btn-sm mt-2 w-100 btn-success">Lihat Laporan</Link>
					<Link to={`/alumni/${data.id}/ternak`} className="btn btn-sm mt-2 w-100 btn-secondary">Lihat Ternak</Link>
					<Button onClick={onDeleteClick} className="btn btn-sm mt-2 w-100 btn-danger">Hapus</Button>
				</Col>
				<hr/>
			</Row>
		</Container>
	)
}

export {
	TernakCard,
	AlumniCard,
};