import {Component} from 'react';
import {Container, Form, FormGroup, Label, Input, Button} from 'reactstrap';
import moment from 'moment';
import {History} from '../../component/History/History';
import Search from '../../component/Search/Search';
import siteConfig from '../../config/config'
import token from '../../helper/token'
import { handleResponse } from '../../helper/SinatriaHelper';

export default class Treatment extends Component {
	constructor(props){
		super(props)
		this.state = {
			formData: {
				date: moment().format("YYYY-MM-DD"),
				code: '',
				diagnosis: '',
				treatment: ''
			},
            historys: [],
            isActiveFilter: false
		}
	}

    refreshHistory = () => {
		//history
		fetch(`${siteConfig.api_url}treatment`,{
			method: "GET",
			headers: {"token": token.getToken()}
		})
		.then(handleResponse)
		.then( resJson => {
			this.setState({
				historys: resJson.data
			})
		})
	}

	componentDidMount(){
		this.refreshHistory();
	}

	handleData = (e) => {
		let name = e.target.name
		let value = e.target.value
		this.setState({
			formData:{
				...this.state.formData,
				[name]:value
			}
		})
	}

	handleSubmit = (e) => {
		e.preventDefault();
		// this.props.handleSubmit(this.state.formData);
		// console.log(this.state.formData);
		// this.props.close();
		fetch(`${siteConfig.api_url}treatment`,{
			method: "POST",
			headers: {
				"token": token.getToken(),
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(this.state.formData)
		})
		.then(handleResponse)
		.then( resJson => {
			this.refreshHistory();
		})
	}

	handleDeleteClick = (id) => {
		fetch(`${siteConfig.api_url}treatment/${id}`,{
			method: "DELETE",
			headers: {
				"token": token.getToken(),
				'Content-Type': 'application/json'
			}
		})
		.then(handleResponse)
		.then( resJson => {
            this.refreshHistory();
		}).catch((err)=>{
			console.log(err);
		})
	}
    
	isActiveFilterHandle = () => {
		this.setState({
			isActiveFilter: !this.state.isActiveFilter
		})
	}

	render(){
		return(
			<>
				<Container className="mt-5">
					<h4>INPUT TREATMENT TERNAK</h4>
					<Form onSubmit={this.handleSubmit}>
						<FormGroup>
							<Label for='tanggal'>Tanggal</Label>
							<Input id='tanggal' name='date' type='date' onChange={this.handleData} value={this.state.formData.date} />
						</FormGroup>
						<FormGroup>
							<Label for='kodeTernak'>Kode Ternak</Label>
							<Input id='kodeTernak' name='code' type="text" onChange={this.handleData} value={this.state.formData.code} />
						</FormGroup>
						<FormGroup>
							<Label for='diagnosis'>Diagnosis</Label>
							<Input id='diagnosis' name='diagnosis' type="textarea" onChange={this.handleData} value={this.state.formData.diagnosis} />
						</FormGroup>
						<FormGroup>
							<Label for='treatment'>Treatment</Label>
							<Input id='treatment' name='treatment' type="textarea" onChange={this.handleData} value={this.state.formData.treatment} />
						</FormGroup>
						<FormGroup>
							<Button color='success' block>INPUT TREATMENT</Button>
						</FormGroup>
					</Form>
				</Container>

                {/* ===== History Section ===== */}
                <section>
                    <hr className="mt-5"/>
                    <Container>
                        <h4><b>Riwayat Treatment</b></h4>
                        <Search onClick={(this.isActiveFilterHandle)} />
                        {this.state.historys.length>=1?(
                            this.state.historys.map(history=>{
                                return <History handleDeleteClick={() => this.handleDeleteClick(history.id)} key={history.id} tanggal={history.date} noternak={history.code} diagnosisBold={history.diagnosis} diagnosis={history.diagnosis} />
                            })
                        ):(
                            <div className="text-center">
                                <strong>Belum Ada Data Riwayat Treatment</strong>
                            </div>
                        )}
                    </Container>
                </section>
			</>
		)
	}
}