import {Component, Fragment} from 'react';
import {Container} from 'reactstrap';
// import Search from '../../component/Search/Search';
import {AlumniCard} from '../../component/Card/Card';
import siteConfig from '../../config/config';
import token from '../../helper/token';
import { handleResponse } from '../../helper/SinatriaHelper';
import DeleteConfirm from './DeleteConfirm';

export default class AlumniPage extends Component{
		state = {
			dataAlumni: [],
			isActiveDeleteConfirm: 'none',
			idAlumni: 0
		}

		deleteAlumni = (id) => {
			this.setState({
				isActiveDeleteConfirm: 'block',
				idAlumni: id
			})
		}

		closeDeleteConfirm = (status) => {
			this.setState({isActiveDeleteConfirm:'none'});
			if (status === 'deleted') {
				fetch(`${siteConfig.api_url}alumni`,{
					method: "GET",
					headers: {"token": token.getToken()}
				})
				.then(handleResponse)
				.then( resJson => {
					this.setState({
						dataAlumni: resJson.data
					})
				})
			}
		}

		componentDidMount() {
			fetch(`${siteConfig.api_url}alumni`,{
				method: "GET",
				headers: {"token": token.getToken()}
			})
			.then(handleResponse)
			.then( resJson => {
				this.setState({
					dataAlumni: resJson.data
				})
			})
		}

		render(){
			return(
				<Fragment>
					<DeleteConfirm display={this.state.isActiveDeleteConfirm} id={this.state.idAlumni} close={this.closeDeleteConfirm}/>
					<Container>
						<h5 className="text-center">Data Alumni</h5>
						<hr/>
						{/* <Search style={{boxShadow:"0 0 20px -10px grey"}}/> */}
						
						{this.state.dataAlumni.length>=1 ? (
							this.state.dataAlumni.map(alumni=>{
								return <AlumniCard data={alumni} key={alumni.id} onDeleteClick={() => this.deleteAlumni(alumni.id)} />
							})
						) : (
							<div className="text-center">
								<strong>Belum Ada Data Almuni</strong>
							</div>
						)}
					</Container>
				</Fragment>

			)
		}
}