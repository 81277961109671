import moment from 'moment';
import {Component} from 'react';
import { withRouter } from 'react-router';
import {Container, Form, FormGroup, Label, Input, Row, CustomInput} from 'reactstrap';
import InputFileImage from '../../component/InputFileImage/InputFileImage';
import siteConfig from '../../config/config'

import token from '../../helper/token';
import {handleResponse} from '../../helper/SinatriaHelper';

class KesehatanHarian extends Component{
	constructor(props){
		super(props)
		this.state = {
			formData: {
                date: moment().format('YYYY-MM-DD')
            },
			gambarTernak: {},
			photo_0: null,
			photo_1: null,
			photo_2: null,
			photo_3: null,
		}
        this.inputKesehatan = ['Mulut','Hidung','Mata','Telinga','Kulit','Bulu','Pernafasan','Perut','Anus','Kuku'];
	}
	handleData = (e) => {
		let name = e.target.name
		let value = e.target.value
		this.setState({
			formData:{
				...this.state.formData,
				[name]:value
			}
		},()=>{
			console.log(this.state.formData)
		})
	}
    
	handleImage = (e) => {
		e.target.parentElement.style.maxHeight = 'fit-content'
		e.target.parentElement.style.overflow = 'visible'
		e.target.parentElement.style.boxShadow = 'none'
		e.target.parentElement.style.borderRadius = '0'
		this.setState({
			gambarTernak:{
				...this.state.gambarTernak,
				[e.target.id]:URL.createObjectURL( e.target.files[0] )
			},
            [e.target.id]: e.target.files[0]
		}, () => {
			//Data belum dimasukkan ke formData.
			console.log(this.state)
		})
	}
    handleSwitch = (e) => {
		let name = e.target.name
		let value = e.target.checked
		this.setState({
			formData:{
				...this.state.formData,
				[name]:value
			}
		},()=>{
			// console.log(this.state.formData)
		})
    }
    handleSubmit = (e) => {
        e.preventDefault();
        let fd = new FormData();
        if(this.state.photo_0)
            fd.append('photo_0',this.state.photo_0);
        if(this.state.photo_1)
            fd.append('photo_1',this.state.photo_1);
        if(this.state.photo_2)
            fd.append('photo_2',this.state.photo_2);
        if(this.state.photo_3)
            fd.append('photo_3',this.state.photo_3);
        for ( var key in this.state.formData ) {
            fd.append(key, this.state.formData[key]);
        }
		fetch(`${siteConfig.api_url}kesehatan`,{
			method: "POST",
			headers: {
				"token": token.getToken()
			},
			body: fd
		})
		.then(handleResponse)
		.then( resJson => {
			this.props.history.push("/");
		})
    }
	render(){
		return(
            <Container>
                <h3 className="text-center mt-5">INPUT KESEHATAN HARIAN</h3>
                <Form onSubmit={this.handleSubmit}>
                    <FormGroup>
                        <Label for="tanggal">Tanggal</Label>
                        <Input id="tanggal" name="date" type="date" onChange={this.handleData} value={this.state.formData.date} required/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="kodeTernak">Kode Ternak</Label>
                        <Input id="kodeTernak" name="code" type="text" onChange={this.handleData} required/>
                    </FormGroup>
                    <br/>
                    <strong>Kesehatan Ternak</strong>
                    {this.inputKesehatan.map((item,index)=>{
                        return (<FormGroup key={index}>
                            <CustomInput type="switch" name={`${item.toLowerCase()}_sehat`} label={`${item} Sehat`} id={`${item.toLowerCase()}_sehat`} onChange={this.handleSwitch} />
                        </FormGroup>)
                    })}
                    <FormGroup>
                        <Label for="kesehatanSehat">Keterangan Sehat</Label>
                        <Input id="kesehatanSehat" name="kesehatan" type="select" onChange={this.handleData} required>
                            <option value="">pilih</option>
                            <option value="Sehat">Sehat</option>
                            <option value="Setengah Sehat">Setengah Sehat</option>
                            <option value="Sakit">Sakit</option>
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="deskripsi">Deskripsi</Label>
                        <Input id="deskripsi" name="deskripsi" type="text" onChange={this.handleData}/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="gambarTernak">Foto</Label>
                        <Container>
                            <Row>
                                <InputFileImage onChange={this.handleImage} id="0" imgSrc={this.state.gambarTernak.photo_0} />
                                <InputFileImage onChange={this.handleImage} id="1" imgSrc={this.state.gambarTernak.photo_1} />
                                <InputFileImage onChange={this.handleImage} id="2" imgSrc={this.state.gambarTernak.photo_2} />
                                <InputFileImage onChange={this.handleImage} id="3" imgSrc={this.state.gambarTernak.photo_3} />
                            </Row>
                        </Container>
                    </FormGroup>
                    <FormGroup>
                        <button className="btn btn-success btn-block">INPUT KESEHATAN</button>
                    </FormGroup>
                </Form>
            </Container>
		)
	}
}
export default withRouter(KesehatanHarian)