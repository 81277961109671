import { Component } from 'react';
import {Container, Row} from 'reactstrap';
import {Home, Profile, Alumni, Logout} from '../SvgImage/SvgImage';
import token from '../../helper/token';
import { handleResponse } from '../../helper/SinatriaHelper';
import siteConfig from '../../config/config';
import { withRouter } from 'react-router';

class AppBar extends Component{
	constructor(props){
		super(props)
		this.state = {
			isAdmin: false
		}
		this.handleLogout = this.handleLogout.bind(this)
	}

	componentDidMount(){
		const urlFetch = fetch(`${siteConfig.api_url}user/me`,{
			method: "GET",
			headers: {"token": token.getToken()}
		  })
		urlFetch
		.then(handleResponse)
		.then( resJson => {
			this.setState({
				isAdmin: resJson.data.role==='admin'
			})
		})
	}

	handleLogout(){
		token.clearToken();
		this.props.history.push("/login")
	}

    render(){
        return(
            <>
				<div style={{height:'100px'}}></div>
				<footer>
					<Container>
						<Row className="text-center">
							{/* ===== Navigasi Bawah =====*/}
							<Home fill="black" height="30" to="/" />
							<Profile fill="black" height="30" to="/profile" />
							{this.state.isAdmin ? <Alumni fill="black" height="30" to="/alumni" /> : null}
							<Logout fill="black" height="30" onClick={this.handleLogout} />
						</Row>
					</Container>
				</footer>
            </>
        )
    }
}

export default withRouter(AppBar)