import {Component} from 'react';
import PopUp from '../../component/PopUp/PopUp';
import {Container, Row, Col, Form, FormGroup, Label, Input, InputGroupAddon, InputGroupText, InputGroup, Button} from 'reactstrap';
import Search from '../../component/Search/Search';
import {InputKelahiranHistory} from '../../component/History/History';
import Filter from '../Filter/Filter';

export default class InputKelahiran extends Component{
	constructor(props){
		super(props)
		this.state = {
			formData: {
				data: {
					tanggalLahir: new Date(),
					noCempe: null,
					noInduk: null,
					noJantan: null,
					bobotLahir: null
				}
			},
			historys:[
				{id:1, tanggalLahir:new Date('01/17/2021'), noCempe:"9434", noInduk:"8451", noJantan:"7623", bobotLahir:"20"},
				{id:2, tanggalLahir:new Date('01/16/2021'), noCempe:"9433", noInduk:"8452", noJantan:"7624", bobotLahir:"21"},
				{id:3, tanggalLahir:new Date('01/15/2021'), noCempe:"9432", noInduk:"8453", noJantan:"7625", bobotLahir:"22"},
				{id:4, tanggalLahir:new Date('01/14/2021'), noCempe:"9431", noInduk:"8454", noJantan:"7626", bobotLahir:"23"},
				{id:5, tanggalLahir:new Date('01/13/2021'), noCempe:"9430", noInduk:"8455", noJantan:"7627", bobotLahir:"24"}
			]
		}
	}
	isActiveFilterHandle = () => {
		this.setState({
			isActiveFilter: !this.state.isActiveFilter
		})
	}
	handleData = (e) => {
		let name = e.target.name
		let value = e.target.value
		this.setState({
			formData:{
				...this.state.formData,
				[name]:value
			}
		})
	}
	dataSubmit = () => {
		console.log(this.state.formData)
		alert('data submitted')
	}
	handleEditClick = (data) => {
		this.setState({
			formData:{
				data: data
			}
		},()=>{
			console.log(this.state.formData)
		})
	}
	render(){
		return(
			<PopUp onClick={this.props.close}>
			{this.state.isActiveFilter?<Filter close={this.isActiveFilterHandle} />:null}
				{/* ===== Section Input ===== */}
				<Container style={{backgroundColor:"#f6f6f6"}}>
					<h3 className="text-center mt-5">INPUT KELAHIRAN</h3>
					<Form>
						<Container>
							<Row className="mt-3">
								<Col xs="6" className="pr-1 pl-0">
									<FormGroup>
										<Label for="tanggalLahir">Tanggal Lahir</Label>
										<Input bsSize="sm" id="tanggalLahir" name="tanggalLahir" type="date" onChange={this.handleData} value={this.state.formData.data.tanggalLahir.toJSON().split('T')[0]}/>
									</FormGroup>
								</Col>
								<Col xs="6" className="pl-1 pr-0">
									<FormGroup>
										<Label for="nomorCempe">Nomor Cempe</Label>
										<Input bsSize="sm" id="nomorCempe" name="nomorCempe" type="text" onChange={this.handleData} value={this.state.formData.data.noCempe}/>
									</FormGroup>
								</Col>
							</Row>
							<Row className="mt-3">
								<Col xs="6" className="pr-1 pl-0">
									<FormGroup>
										<Label for="nomorInduk">Nomor Induk</Label>
										<Input bsSize="sm" id="nomorInduk" name="nomorInduk" type="text" onChange={this.handleData} value={this.state.formData.data.noInduk}/>
									</FormGroup>
								</Col>
								<Col xs="6" className="pl-1 pr-0">
									<FormGroup>
										<Label for="nomorJantan">Nomor Jantan</Label>
										<Input bsSize="sm" id="nomorJantan" name="nomorJantan" type="text" onChange={this.handleData} value={this.state.formData.data.noJantan}/>
									</FormGroup>
								</Col>
							</Row>
							<Row className="mt-3">
								<Col xs="6" className="pr-1 pl-0">
									<FormGroup>
										<Label for="bobotLahir">Bobot Lahir</Label>
										<InputGroup size="sm">
											<Input bsSize="sm" id="bobotLahir" name="bobotLahir" type="number" onChange={this.handleData} value={this.state.formData.data.bobotLahir}/>
									        <InputGroupAddon addonType="append">
									    	    <InputGroupText>Kg</InputGroupText>
									        </InputGroupAddon>
									    </InputGroup>
									</FormGroup>
								</Col>
								<Col xs="6" className="pl-1 pr-0">
									<FormGroup>
										<Button onClick={this.dataSubmit} style={{marginTop:'2.2em'}} color="success" size="sm" block>INPUT</Button>
									</FormGroup>
								</Col>
							</Row>
						</Container>
					</Form>
				</Container>

				{/* ===== History Section ===== */}
				<section>
					<Container>
						<h4><b>Riwayat Kelahiran Ternak</b></h4>
						<Search onClick={(this.isActiveFilterHandle)} />
					</Container>
					{this.state.historys.map(history=>{
						return <InputKelahiranHistory data={history} handleEditClick={data=>this.handleEditClick(data)}/>
					})}
				</section>

			</PopUp>
		)
	}
}