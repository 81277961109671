import { Component } from 'react';
import {Bars} from '../SvgImage/SvgImage';
import Sidebar from '../Sidebar/Sidebar';

class Header extends Component{
	state = {
		isActiveBars: false,
	}
	handleBars = () => {
		this.setState({
			isActiveBars: !this.state.isActiveBars
		})
	}
    render(){
        return(
            <>
                <header>
                    <div className="top-header">
                        <img src="/img/sinatriafarm-logo.png" alt="sinatriafarm-logo" className="logo"/>
                        <Bars onClick={this.handleBars} />
                    </div>
                </header>

                <Sidebar off={status=>this.handleBars()} isOpen={this.state.isActiveBars} />
            </>
        )
    }
}

export default Header;