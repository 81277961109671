import {Component, Fragment} from 'react';
import querystring from 'query-string';
import {MainButton} from '../../component/MenuItems/MenuItems';
import {History2} from '../../component/History/History';
import Search from '../../component/Search/Search';
import TambahPenjualan from '../../form/Keuangan/TambahPenjualan';
import TambahPembelian from '../../form/Keuangan/TambahPembelian';
import TambahPengeluaran from '../../form/Keuangan/TambahPengeluaran';

import {formatRupiah,handleResponse} from '../../helper/SinatriaHelper'
import token from '../../helper/token';
import siteConfig from '../../config/config';

import Filter from '../../form/Filter/Filter';

export default class Penjualan extends Component{
	constructor(props){
		super(props)
		this.state = {
			pageData: this.props.pageData,
			isActiveForm: false,
			isActiveFilter: false,
			searchVal: '',
			total: this.props.pageData.saldoTotal,
			historys: [],
			categories: [],
			category: ''
		}
	}

	componentDidMount(){
		this.refreshHistory();
	}

	refreshHistory() {
		const urlFetch = fetch(`${siteConfig.api_url}${this.state.pageData.path}/report`,{
			method: "GET",
			headers: {"token": token.getToken()}
		  })
		urlFetch.then(handleResponse).then( resJson => {
			this.setState({
				total: resJson.data.total
			})
		})
		//history
		fetch(`${siteConfig.api_url}${this.state.pageData.path}`,{
			method: "GET",
			headers: {"token": token.getToken()}
		})
		.then(handleResponse)
		.then( resJson => {
			this.setState({
				historys: resJson.data
			})
		})
		//categories
		let params = {
			type: this.state.pageData.path
		}
		fetch(`${siteConfig.api_url}category?${querystring.stringify(params)}`,{
			method: "GET",
			headers: {
				"token": token.getToken(),
				'Content-Type': 'application/json'
			}
		})
		.then(handleResponse)
		.then( resJson => {
			this.setState({
				categories: resJson.data
			})
		})
	}

	scrollToTop(){
		document.body.scrollTop = 0; // For Safari
		document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
	}
	
	handleMainButtonClick = () => {
		this.setState({
			isActiveForm: !this.state.isActiveForm
		})
	}

	handleSearchFilterClose = () => {
		this.setState({
			isActiveFilter: !this.state.isActiveFilter
		})
		this.scrollToTop()
	}

	handleSearchChange =  e => {
		console.log(e.target.value)
	}

	handleFilterSubmit = (params) => {
		fetch(`${siteConfig.api_url}${this.state.pageData.path}?${querystring.stringify(params)}`,{
			method: "GET",
			headers: {"token": token.getToken()}
		})
		.then(handleResponse)
		.then( resJson => {
			this.setState({
				historys: resJson.data
			})
		})
	}

	handleHistoryDelete = id => {
		fetch(`${siteConfig.api_url}${this.state.pageData.path}/${id}`,{
			method: "DELETE",
			headers: {
				"token": token.getToken(),
				'Content-Type': 'application/json'
			}
		})
		.then(handleResponse)
		.then( () => {
			this.refreshHistory();
		})
	}

	render(){
		return(
			<Fragment>
				{/* ==== Form ==== */}
				{ (this.state.isActiveForm && this.state.pageData.formName === 'TambahPenjualan') ? <TambahPenjualan close={this.handleMainButtonClick} categories={this.state.categories}/> : 
					 (this.state.isActiveForm && this.state.pageData.formName === 'TambahPengeluaran') ? <TambahPengeluaran close={this.handleMainButtonClick} categories={this.state.categories} /> : 
				 		 (this.state.isActiveForm && this.state.pageData.formName === 'TambahPembelian') ? <TambahPembelian close={this.handleMainButtonClick} categories={this.state.categories} /> : null}
				
				{/* === Filter === */}
				{this.state.isActiveFilter? <Filter kategori={this.state.categories} close={this.handleSearchFilterClose}  handleSubmit={this.handleFilterSubmit} /> : null}

				 {/* === Second Header === */}
				<section style={{backgroundColor: "#187D25", borderRadius:"0 0 30px 30px", padding:"15px 20px", color: "#fff" }}>
					<p className="d-block m-0">{this.state.pageData.secondHeaderTitle}</p>	
					<h2>{formatRupiah(this.state.total||0)}</h2>
				</section>

				{/* === Tombol Utama === */}
				<section>
					<MainButton onClick={this.handleMainButtonClick} text={this.state.pageData.mainButtonText} />
				</section>

				{/* === Search & History === */}
				<section style={{backgroundColor:'#f5f5f5', borderRadius:'30px 30px 0 0', padding:'15px 15px 50px 15px'}}>
					<h5>{this.state.pageData.historyTitle}</h5>
					<Search onChannge={this.handleSearchChange} onClick={this.handleSearchFilterClose}/>
					{this.state.historys.length>=1 ? (
						this.state.historys.map(history=>{
							return <History2 handleDeleteClick={id=>this.handleHistoryDelete(id)} text={[history.id,history.date,history.category,history.price,history.quantity,this.state.pageData.path]} key={history.id}/>
						})
					) : (
						<div className="text-center">
							<strong>Belum Ada Riwayat</strong>
						</div>
					)}
				</section>
			</Fragment>
		)
	}
}