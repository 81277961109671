import moment from 'moment';
import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import siteConfig from '../../config/config';

const DetailTernak = ({data,modal,toggle}) => {
    return (
        <>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Detail Ternak</ModalHeader>
                <ModalBody>
                    <table className="table">
                        <tbody>
                            <tr>
                                <th className="text-right" style={{width:'100px'}}>Tanggal Masuk/Pembelian</th>
                                <td>{moment(data.date).format('YYYY-MM-DD')}</td>
                            </tr>
                            <tr>
                                <th className="text-right">Kode Ternak</th>
                                <td>{data.code}</td>
                            </tr>
                            <tr>
                                <th className="text-right">Jenis Ternak</th>
                                <td>{data.jenis}</td>
                            </tr>
                            <tr>
                                <th className="text-right">Jenis Kelamin</th>
                                <td>{data.kelamin}</td>
                            </tr>
                            <tr>
                                <th className="text-right">Tujuan Program</th>
                                <td>{data.tujuan}</td>
                            </tr>
                            <tr>
                                <th className="text-right">Umur</th>
                                <td>{data.umur} bulan</td>
                            </tr>
                            <tr>
                                <th className="text-right">Status Ternak</th>
                                <td>{data.status}</td>
                            </tr>
                            <tr>
                                <th className="text-right">Harga Beli</th>
                                <td>{data.harga_beli}</td>
                            </tr>
                            <tr>
                                <th className="text-right">Nama Supplier</th>
                                <td>{data.nama_supplier}</td>
                            </tr>
                            <tr>
                                <th className="text-right">Foto 1</th>
                                <td><img src={data.img0?siteConfig.img_url+data.img0:"/img/inputimagedefault.png"} alt="" style={{maxWidth:'90px',borderRadius:'10px'}} /></td>
                            </tr>
                            <tr>
                                <th className="text-right">Foto 2</th>
                                <td><img src={data.img1?siteConfig.img_url+data.img1:"/img/inputimagedefault.png"} alt="" style={{maxWidth:'90px',borderRadius:'10px'}} /></td>
                            </tr>
                            <tr>
                                <th className="text-right">Foto 3</th>
                                <td><img src={data.img2?siteConfig.img_url+data.img2:"/img/inputimagedefault.png"} alt="" style={{maxWidth:'90px',borderRadius:'10px'}} /></td>
                            </tr>
                            <tr>
                                <th className="text-right">Foto 4</th>
                                <td><img src={data.img3?siteConfig.img_url+data.img3:"/img/inputimagedefault.png"} alt="" style={{maxWidth:'90px',borderRadius:'10px'}} /></td>
                            </tr>
                        </tbody>
                    </table>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={toggle}>Close</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default DetailTernak;