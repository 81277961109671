import {Component} from 'react';
import PopUp from '../../component/PopUp/PopUp';
import {Container, Row, Col, Label, Input, Button, Form} from 'reactstrap';
import moment from 'moment';

import {handleResponse} from '../../helper/SinatriaHelper';
import token from '../../helper/token';
import siteConfig from '../../config/config';

export default class UbahStatusTernak extends Component {
	constructor(props){
		super(props)
		this.state = {
			formData: this.props.data
		}
	}
	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.data.code !== prevState.formData.code) {
	    	return { 
	    		formData:{
		    		date: nextProps.data.date || moment().format('YYYY-MM-DD'),
		    		code: nextProps.data.code,
		    		status: nextProps.data.currentStatus,
	    		}
	    	};
	    }
	    return null
    }
	handleData = (e) => {
		this.setState({
			formData:{
				...this.state.formData,
				[e.target.name]:e.target.value
			}	
		}, ()=>{console.log(this.state)})
	}

	handleSubmit = (e) => {
		e.preventDefault();
		fetch(`${siteConfig.api_url}statusharian`,{
			method: "POST",
			headers: {
				"token": token.getToken(),
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(this.state.formData)
		})
		.then(handleResponse)
		.then( resJson => {
			this.props.ubahStatusSubmit();
		})
	}

	render(){
		let active = {display:this.props.display}
		return(
			<div style={active}>
			<PopUp onClick={this.props.close} style={{zIndex:'101'}}>
				<Container>
					<h2 className="text-center mt-5">Ubah Status</h2>
					<Form onSubmit={this.handleSubmit}>
						<Row>
							<Col xs="6">
								<Label for="tanggal">Tanggal</Label>
								<Input name="date" id="tanggal" type="date" onChange={this.handleData} value={this.state.formData.date}/>
							</Col>
							<Col xs="6">
								<Label for="kodeTernak">Kode Ternak</Label>
								<Input name="code" id="kodeTernak" type="text" onChange={this.handleData} value={this.state.formData.code} readOnly/>
							</Col>
							<Col xs="12" className="mt-2">
								<Label for="status">Status</Label>
								<Input name="status" id="status" type="select" onChange={this.handleData} value={this.state.formData.status}>
									<option value="">pilih</option>
									<option value="sakit">sakit</option>
									<option value="sehat">sehat</option>
									<option value="mati">mati</option>
									<option value="dijual">dijual</option>
									<option value="dibeli">dibeli</option>
								</Input>
								<Button color="success" block className="my-4">UBAH</Button>
							</Col>
						</Row>
					</Form>
				</Container>
			</PopUp>
			</div>
		)
	}
}