import {Component} from 'react';
import {Container, Row, Col, Form, FormGroup, Label, Input, Alert} from 'reactstrap';
import Search from '../../component/Search/Search';
import {InputPerkawinanHistory} from '../../component/History/History';
import Filter from '../../form/Filter/Filter';
import moment from 'moment';
import siteConfig from '../../config/config'
import token from '../../helper/token'
import { withRouter } from 'react-router';
import { handleResponse } from '../../helper/SinatriaHelper';

class Perkawinan extends Component{
	constructor(props){
		super(props)
		this.state = {
			formData: {
                date: moment().format('YYYY-MM-DD'),
				data: {
					tanggal: new Date(),
					noTernakBetina: null,
					noTernakJantan: null,
				}
			},
			historys:[],
            successAlertVisible: false,
            successMessage: 'Data berhasil disimpan.'
		}
	}
    
	refreshHistory = () => {
		//history
		fetch(`${siteConfig.api_url}perkawinan`,{
			method: "GET",
			headers: {"token": token.getToken()}
		})
		.then(handleResponse)
		.then( resJson => {
			this.setState({
				historys: resJson.data
			})
		}).catch((err)=>{
            console.log(err);
        })
	}

	componentDidMount(){
		this.refreshHistory();
	}

	isActiveFilterHandle = () => {
		this.setState({
			isActiveFilter: !this.state.isActiveFilter
		})
	}
	handleData = (e) => {
		let name = e.target.name
		let value = e.target.value
		this.setState({
			formData:{
				...this.state.formData,
				[name]:value
			}
		},()=>{
			console.log(this.state.formData)
		})
	}
	handleHistoryPatch = (data) => {
		this.setState({
			formData:{
				data: data
			}
		})
	}
	handleHistoryDelete = id => {
		fetch(`${siteConfig.api_url}perkawinan/${id}`,{
			method: "DELETE",
			headers: {
				"token": token.getToken(),
				'Content-Type': 'application/json'
			}
		})
		.then(handleResponse)
		.then( resJson => {
            this.refreshHistory();
		}).catch((err)=>{
			console.log(err);
		})
	}

	handleSubmit = (e) => {
        e.preventDefault();
		fetch(`${siteConfig.api_url}perkawinan`,{
			method: "POST",
			headers: {
				"token": token.getToken(),
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(this.state.formData)
		})
		.then(handleResponse)
		.then( resJson => {
            this.setState({
                formData:{
                    ...this.state.formData,
                    jantan: '',
                    betina: ''
                }
            })
            this.refreshHistory();
            this.setState({successAlertVisible: true})
		}).catch((err)=>{
			console.log(err);
		})
	}
	render(){
		return(
			<>
			{this.state.isActiveFilter?<Filter close={this.isActiveFilterHandle} />:null}
				{/* ===== Section Input ===== */}
				<Container>
					<h3 className="text-center mt-5">INPUT PERKAWINAN</h3>
                    <Alert color="success" isOpen={this.state.successAlertVisible} toggle={()=> this.setState({successAlertVisible: false})}>
                        {this.state.successMessage}
                    </Alert>
					<Form onSubmit={this.handleSubmit}>
						<FormGroup>
							<Label for="tanggal">Tanggal Perkawinan</Label>
							<Input id="tanggal" name="date" type="date" onChange={this.handleData} value={this.state.formData.date}/>
						</FormGroup>
						<FormGroup>
							<Container>
								<Row className="mt-3">
									<Col xs="5" className="pr-1 pl-0">
										<FormGroup>
											<Label for="nomorBetina">Nomor Betina</Label>
											<Input bsSize="sm" id="nomorBetina" name="betina" type="text" onChange={this.handleData} value={this.state.formData.betina}/>
										</FormGroup>
									</Col>
									<Col xs="5" className="pl-1 pr-1">
										<FormGroup>
											<Label for="nomorJantan">Nomor Jantan</Label>
											<Input bsSize="sm" id="nomorJantan" name="jantan" type="text" onChange={this.handleData} value={this.state.formData.jantan}/>
										</FormGroup>
									</Col>
									<Col xs="2" className="pl-1">
										<FormGroup>
											<button style={{marginTop:'32px'}} className="btn btn-success btn-sm">INPUT</button>
										</FormGroup>
									</Col>
								</Row>
							</Container>
						</FormGroup>
					</Form>
				</Container>

				{/* ===== History Section ===== */}
				<section>
					<Container>
						<h4><b>Riwayat Perkawinan</b></h4>
						<Search onClick={(this.isActiveFilterHandle)} />
					</Container>
					{this.state.historys.map(history=>{
						return <InputPerkawinanHistory key={history.id} data={history} handleEditClick={data=>this.handleHistoryPatch(data)} handleDeleteClick={id=>this.handleHistoryDelete(id)} />
					})}
				</section>

			</>
		)
	}
}

export default withRouter(Perkawinan)