import {Component} from 'react';
import PopUp from '../../component/PopUp/PopUp';
import {Container, Row, Col, Form, FormGroup, Label, Input} from 'reactstrap';
import Search from '../../component/Search/Search';
import {InputPerkawinanHistory} from '../../component/History/History';
import Filter from '../Filter/Filter';

export default class InputPerkawinan extends Component{
	constructor(props){
		super(props)
		this.state = {
			formData: {
				data: {
					tanggal: new Date(),
					noTernakBetina: null,
					noTernakJantan: null,
				}
			},
			historys:[
				{id:1, tanggal:new Date('06/17/2021'), noTernakBetina:"7214", noTernakJantan:"8121"},
				{id:2, tanggal:new Date('06/18/2021'), noTernakBetina:"7213", noTernakJantan:"8122"},
				{id:3, tanggal:new Date('06/19/2021'), noTernakBetina:"7212", noTernakJantan:"8123"},
				{id:4, tanggal:new Date('06/20/2021'), noTernakBetina:"7211", noTernakJantan:"8124"},
				{id:5, tanggal:new Date('06/21/2021'), noTernakBetina:"7210", noTernakJantan:"8125"}
			]
		}
	}
	isActiveFilterHandle = () => {
		this.setState({
			isActiveFilter: !this.state.isActiveFilter
		})
	}
	handleData = (e) => {
		let name = e.target.name
		let value = e.target.value
		this.setState({
			formData:{
				...this.state.formData,
				[name]:value
			}
		},()=>{
			console.log(this.state.formData)
		})
	}
	dataSubmit = () => {
		console.log(this.state.formData)
		alert('data submitted')
	}
	handleHistoryPatch = (data) => {
		this.setState({
			formData:{
				data: data
			}
		})
	}
	handleHistoryDelete = id => {
		alert('hapus '+id)
	}
	render(){
		return(
			<PopUp onClick={this.props.close}>
			{this.state.isActiveFilter?<Filter close={this.isActiveFilterHandle} />:null}
				{/* ===== Section Input ===== */}
				<Container style={{backgroundColor:"#f6f6f6"}}>
					<h3 className="text-center mt-5">INPUT PERKAWINAN</h3>
					<Form>
						<FormGroup>
							<Label for="tanggal">Tanggal Penimbangan</Label>
							<Input id="tanggal" name="tanggal" type="date" onChange={this.handleData} value={this.state.formData.data.tanggal.toJSON().split('T')[0]}/>
						</FormGroup>
						<FormGroup>
							<Container>
								<Row className="mt-3">
									<Col xs="5" className="pr-1 pl-0">
										<FormGroup>
											<Label for="nomorBetina">Nomor Betina</Label>
											<Input bsSize="sm" id="nomorBetina" name="nomorBetina" type="text" onChange={this.handleData} value={this.state.formData.data.noTernakBetina}/>
										</FormGroup>
									</Col>
									<Col xs="5" className="pl-1 pr-1">
										<FormGroup>
											<Label for="nomorJantan">Nomor Jantan</Label>
											<Input bsSize="sm" id="nomorJantan" name="nomorJantan" type="text" onChange={this.handleData} value={this.state.formData.data.noTernakJantan}/>
										</FormGroup>
									</Col>
									<Col xs="2" className="pl-1">
										<FormGroup>
											<button style={{marginTop:'32px'}} className="btn btn-success btn-sm">INPUT</button>
										</FormGroup>
									</Col>
								</Row>
							</Container>
						</FormGroup>
					</Form>
				</Container>

				{/* ===== History Section ===== */}
				<section>
					<Container>
						<h4><b>Riwayat Perkawinan</b></h4>
						<Search onClick={(this.isActiveFilterHandle)} />
					</Container>
					{this.state.historys.map(history=>{
						return <InputPerkawinanHistory data={history} handleEditClick={data=>this.handleHistoryPatch(data)} handleDeleteClick={id=>this.handleHistoryDelete(id)} />
					})}
				</section>

			</PopUp>
		)
	}
}