import {Search as SearchSVG, Filter} from '../SvgImage/SvgImage';
import {Row, Col} from 'reactstrap';
import './Search.css';

const Search = ({onChange, onClick}) => {
	return(
		<div>
			<Row className="search-wrapper">		
				<Col xs='10'>
					<div className="search" style={{display:'none'}}>
						<SearchSVG width='20' fill="#bbb"/>
						<input type="text" onChange={onChange}/>
					</div>
				</Col>
				<Col xs='1' onClick={onClick}>
						<Filter fill="#bbb"/>
				</Col>
			</Row>
			<hr style={{borderTop:"2px solid #bbb"}}/>
		</div>
	)
}

export default Search;