import {Component} from 'react';
import PopUp from '../../component/PopUp/PopUp';
import {Container, Form, FormGroup, Label, Input, Button} from 'reactstrap';
import moment from 'moment';

export default class InputTreatmentTernak extends Component {
	constructor(props){
		super(props)
		this.state = {
			formData: {
				date: moment().format("YYYY-MM-DD"),
				code: '',
				diagnosis: '',
				treatment: ''
			}
		}
	}

	handleData = (e) => {
		let name = e.target.name
		let value = e.target.value
		this.setState({
			formData:{
				...this.state.formData,
				[name]:value
			}
		})
	}

	handleSubmit = (e) => {
		e.preventDefault();
		this.props.handleSubmit(this.state.formData);
		console.log(this.state.formData);
		this.props.close();
	}

	render(){
		return(
			<PopUp onClick={this.props.close}>
				<Container className="mt-5">
					<h4>INPUT TREATMENT TERNAK</h4>
					<Form onSubmit={this.handleSubmit}>
						<FormGroup>
							<Label for='tanggal'>Tanggal</Label>
							<Input id='tanggal' name='date' type='date' onChange={this.handleData} value={this.state.formData.date} />
						</FormGroup>
						<FormGroup>
							<Label for='kodeTernak'>Kode Ternak</Label>
							<Input id='kodeTernak' name='code' type="text" onChange={this.handleData} value={this.state.formData.code} />
						</FormGroup>
						<FormGroup>
							<Label for='diagnosis'>Diagnosis</Label>
							<Input id='diagnosis' name='diagnosis' type="textarea" onChange={this.handleData} value={this.state.formData.diagnosis} />
						</FormGroup>
						<FormGroup>
							<Label for='treatment'>Treatment</Label>
							<Input id='treatment' name='treatment' type="textarea" onChange={this.handleData} value={this.state.formData.treatment} />
						</FormGroup>
						<FormGroup>
							<Button color='success' block>INPUT TREATMENT</Button>
						</FormGroup>
					</Form>
				</Container>
			</PopUp>
		)
	}
}