import moment from 'moment';
import {Component} from 'react';
import { withRouter } from 'react-router';
import {Container, Form, FormGroup, Label, Input, Row, Col} from 'reactstrap';
import siteConfig from '../../config/config';
import { formatRupiah, handleResponse } from '../../helper/SinatriaHelper';
import token from '../../helper/token';

class LaporanFarm extends Component{
	constructor(props){
		super(props)
		this.state = {
			startDate: moment().subtract(1,'month').format('YYYY-MM-DD'),
			endDate: moment().format('YYYY-MM-DD'),
			sales: 0,
			purchase: 0,
			spend: 0,
			statusTernak: '',
			userIdParams: (this.props.match.params.alumniID?`userid=${this.props.match.params.alumniID}`:''),
			recap: {
				total: 0,
				"Pejantan": 0,
				"Induk Bunting": 0,
				"Induk Menyusui": 0,
				"Cempe Jantan": 0,
				"Cempe Betina": 0,
				"Lepas Sapih Jantan": 0,
				"Lepas Sapih Betina": 0,
				"Dara": 0,
				"Afkir": 0
			}
		}
		this.handleData = this.handleData.bind(this)
		this.updateReport = this.updateReport.bind(this)
	}

	updateReport(){
		//penjualan
		fetch(`${siteConfig.api_url}sales/report?startdate=${this.state.startDate}&enddate=${this.state.endDate}&${this.state.userIdParams}`,{
			method: "GET",
			headers: {"token": token.getToken()}
		})
		.then(handleResponse)
		.then( resJson => {
			this.setState({
				sales: resJson.data.total||0
			})
		})
		//pembelian
		fetch(`${siteConfig.api_url}purchase/report?startdate=${this.state.startDate}&enddate=${this.state.endDate}&${this.state.userIdParams}`,{
			method: "GET",
			headers: {"token": token.getToken()}
		})
		.then(handleResponse)
		.then( resJson => {
			this.setState({
				purchase: resJson.data.total||0
			})
		})
		//pengeluaran
		fetch(`${siteConfig.api_url}spend/report?startdate=${this.state.startDate}&enddate=${this.state.endDate}&${this.state.userIdParams}`,{
			method: "GET",
			headers: {"token": token.getToken()}
		})
		.then(handleResponse)
		.then( resJson => {
			this.setState({
				spend: resJson.data.total||0
			})
		})
	}

	componentDidMount(){
		this.updateReport();
	}

	handleData(e){
		let name = e.target.name
		let value = e.target.value
		this.setState({
			[name]:value
		},()=>{
			console.log(this.state)
			this.updateReport()
		})
	}

	updateRecap = (status) => {
		fetch(`${siteConfig.api_url}recap?status=${status}&startdate=${this.state.startDate}&enddate=${this.state.endDate}&${this.state.userIdParams}`,{
			method: "GET",
			headers: {"token": token.getToken()}
		})
		.then(handleResponse)
		.then( resJson => {
			this.setState({
				recap: resJson.data
			},()=>{
				console.log(this.state.recap);
			})
		})
		.catch((err)=>{
			console.log(err);
		})
	}

	handleChangeStatus = (e) => {
		let name = e.target.name
		let value = e.target.value
		this.setState({
			[name]:value
		},()=>{
			this.updateRecap(value);
		})
	}

	render(){
		return(
			<Container>
				<Form className="mb-3 mx-3">
					<FormGroup>
						<Label>Rentang Waktu</Label>
						<div>
							<Input name="startDate" type="date" style={{width:"45%", float:'left', border:'1px solid #eee', borderRadius:'5px', padding:'6px 6px', textAlign:'center'}} onChange={this.handleData} value={this.state.startDate} />
							<p style={{width:'10%',float:'left', marginTop:'.4rem'}} className="text-center">s/d</p>
							<Input name="endDate" type="date" style={{width:"45%", float:'left', border:'1px solid #eee', borderRadius:'5px', padding:'6px 6px', textAlign:'center'}} onChange={this.handleData} value={this.state.endDate} />
							<div style={{clear:'both'}}></div>
						</div>
					</FormGroup>
				</Form>

				<Container className='text-center'>
					<p><b>Rekapitulasi Laporan Keuangan</b></p>
					<Row className='p-1'>
						<Col style={{borderRadius:'15px', background:'#187D25', color:'#fff', padding:'10px 5px'}}>
							<p className="mb-0">Total Penjualan</p>
							<h5>{formatRupiah(this.state.sales)}</h5>
						</Col>
					</Row>
					<Row>
						<Col xs='6' className='p-1'>
							<div style={{borderRadius:'15px', background:'#187D25', color:'#fff', padding:'5px'}}>
								<p className="mb-0 mt-2">Total Pembelian</p>
								<p><b>{formatRupiah(this.state.purchase)}</b></p>
							</div>
						</Col>
						<Col xs='6' className='p-1'>
							<div style={{borderRadius:'15px', background:'#187D25', color:'#fff', padding:'5px'}}>
								<p className="mb-0 mt-2">Total Pengeluaran</p>
								<p><b>{formatRupiah(this.state.spend)}</b></p>
							</div>
						</Col>
					</Row>
				</Container>

				<Container className='pt-4'>
					<p className='text-center'><b>Rekapitulasi Jumlah ternak</b></p>
					<Form>
						<FormGroup>
							<Label for="pilihStatusTernak">Pilih Status Ternak</Label>
							<Input id="pilihStatusTernak" name="statusTernak" type="select" onChange={this.handleChangeStatus} value={this.state.statusTernak} >
								<option value="">pilih</option>
								<option value="sehat">Sehat</option>
								<option value="sakit">Sakit</option>
								<option value="mati">Mati</option>
								<option value="dibeli">Dibeli</option>
								<option value="dijual">Dijual</option>
							</Input>
						</FormGroup>
					</Form>
					{this.state.statusTernak?(
						<>
							<div className="w-50 m-auto text-center" style={{borderRadius:'15px', background:'#187D25', color:'#fff', padding:'1px 0'}}>
								<p className="mb-0 mt-2">Total Ternak {this.state.statusTernak.toUpperCase()}</p>
								<p><b>{this.state.recap.total}</b></p>
							</div>
							<div className='mt-2'>
								<table className='m-auto'>
									<tbody>
										<tr>
											<td>pejantan</td>
											<td>: {this.state.recap["Pejantan"]}</td>
										</tr>
										<tr>
											<td>induk bunting</td>
											<td>: {this.state.recap["Induk Bunting"]}</td>
										</tr>
										<tr>
											<td>induk menyusui</td>
											<td>: {this.state.recap["Induk Menyusui"]}</td>
										</tr>
										<tr>
											<td>cempe jantan</td>
											<td>: {this.state.recap["Cempe Jantan"]}</td>
										</tr>
										<tr>
											<td>cempe betina</td>
											<td>: {this.state.recap["Cempe Betina"]}</td>
										</tr>
										<tr>
											<td>lepas sapih jantan</td>
											<td>: {this.state.recap["Lepas Sapih Jantan"]}</td>
										</tr>
										<tr>
											<td>lepas sapih betina</td>
											<td>: {this.state.recap["Lepas Sapih Betina"]}</td>
										</tr>
										<tr>
											<td>dara</td>
											<td>: {this.state.recap["Dara"]}</td>
										</tr>
										<tr>
											<td>afkir</td>
											<td>: {this.state.recap["Afkir"]}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</>
					):''}
				</Container>
				<div style={{height:'100px'}}>{/*Space*/}</div>
			</Container>
		)
	}
}

export default withRouter(LaporanFarm)